import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../App'
import '../../css/music.css';

class Music extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      song: [],
      file: "",
      mp3: null,
      redirect: false,
    };
  }

  componentDidMount() {
    let link = this.props.location.pathname.split("/")
    let slug = link[link.length-1]
    var url = this.state.constants.hostname + '/api/music/show?slug=' + slug;
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
      .then(res => {
        if (!res.ok) {
          this.setState({ redirect: true });
          return false;
        }
        return res.json();
      })
      .then(data => {
        if (!data) { return; }
        this.setState({ song : data[0] });
      })
      .catch(error => { console.error(error); });
    }

    renderSongs(song) {
      return (
        <tr key={song} className='musicRow'>
          <td className='musicEntry'>
            {song.split(".jpg")[0]}
          </td>
          <td className='musicEntry'>
            <Link
              target="_blank"
              to={'/music/' + this.state.song.Title + "/show/" + song}
            >
              <div className='oneline center clickable'>
                <FontAwesomeIcon icon="image" className='faImage'/>
                {song.split(".jpg")[0]}
              </div>
            </Link>
          </td>
          <td className='musicEntry'>
            <Link
              target="_blank"
              to={'/music/' + this.state.song.Title + "/show/" + song.split(".")[0]+".pdf"}
            >
              <div className='oneline center  clickable'>
                <FontAwesomeIcon icon="image" className='faImage'/>
                {song.split(".jpg")[0]}
              </div>
            </Link>
          </td>
        </tr>
      );
    }

    render() {
        return (
          <div className="music">
            {this.state.redirect?
              <Redirect to={`/404`} />
              :
              null
            }
            <h3 className='musicTitle'>{this.state.song.Title}</h3>
            {this.state.song.length !== 0?
              <div className='musicDiv'>
                <table className='musicTable'>
                  <thead>
                    <tr className='musicRow'>
                      <th className='musicHeader'>Section</th>
                      <th className='musicHeader'>JPG</th>
                      <th className='musicHeader'>PDF</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.song.Jpg.map((song) =>
                      this.renderSongs(song)
                    )}
                  </tbody>
                </table>
              </div>
              :
              null
              }
            <h3>Audio File</h3>
            {this.state.song.Mp3?
              <audio controls>
                <source
                  src={this.state.constants.hostname + '/api/music/mp3?title=' + this.state.song.Title}
                  type="audio/mpeg"
                ></source>
              Your browser does not support the audio element.
              </audio>
              :
              <p>None</p>
            }
            <h3>Special Notes</h3>
            {this.state.song.Comment!==""?
              <p>{this.state.song.Comment}</p>
              :
              <div>
                {this.state.song.Video!==""?
                  <iframe width="560" height="315"
                    src={this.state.song.Video} title={this.state.song.Title}>
                  </iframe>
                  :
                  <p>None</p>
                }
              </div>
            }
          </div>
        );
    }
}

export default withRouter(Music)
