import React, { Component } from 'react';
import { firebase } from "../index.js";
import '../css/login.css';

function signInGoogle(){
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).catch(function(error) {
        console.error("Error " + error.code + ": " + error.message);
    });
}

export class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
    }
  }

  render() {
    return (
      <div id="login" className='login'>
        <div className='signin'>
          <p className='t1 b signinText'>RIT Pep Band</p>
          <p className='t3 signinText'>Welcome to the RIT Pep Band website</p>
          <p className='t3 signinText'>
            Serving "The best Pep Band in the land" for all your musical needs.
          </p>
          {this.props.state.classic?
            <div>
              <p className='signinText'>{this.props.message}</p>
              <div className='center'>
                <button onClick={signInGoogle} className='signinButton'>
                  <img className='sigininImage' src="/media/google.png"
                    alt="Google Login"/>
                </button>
              </div>
              <p className='optionLogin' onClick={this.props.switchClassic}>
                Or Click here to sign in with a username and password (Non-RIT Students)
              </p>
            </div>
            :
            <div>
              {this.props.state.failed?
                <p className='signinText'>Incorrect username or password</p>
                :
                <p className='signinText'>Please sign in</p>
              }
              <div className='center'>
                <form className='backupForm'>
                  <label className='loginLabel'>
                    Username:
                    <input
                      type="text"
                      name="username"
                      onChange={this.props.userChange}
                      value={this.props.state.username}
                      className="loginInput"
                    />
                  </label>
                  <label className='loginLabel'>
                    Password:
                    <input
                      type="password"
                      name="password"
                      onChange={this.props.passChange}
                      value={this.props.state.password}
                      className="loginInput"
                    />
                  </label>
                  <div className='center'>
                    <button
                      onClick={this.props.handleLogin}
                      className='loginSubmit'
                    >
                      Submit
                    </button>
                  </div>
                  <p className='optionLogin' onClick={this.props.switchClassic}>
                    Or Click here to sign in with Google (RIT Students)
                  </p>
                </form>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
