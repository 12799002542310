import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { blankCheck, nameCheck, dceCheck, sectionCheck } from '../../Errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App.js';

class CSV extends Component {

  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
      orderedInput:[],
      parsing: false,
      finished: false,
      Submit: false,
      errorUsers: [],
    }
  }

  sendData = (newUser, idx, len) => {
    var url = this.state.constants.hostname + '/api/secretary/register?userInfo=' + JSON.stringify(newUser);
    return new Promise((resolve, reject) => {
      fetch(url,
        {
          body: JSON.stringify({ user: newUser }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization':`${localStorage.getItem(USERID)}`
          },
          method: 'POST',
        })
      .then(res => {
        if (!res.ok) {
          reject("Entry already exists")
        } else {
          resolve();
        }
      });
    })
  }

  resolveData = (errorInput) => {
    if (errorInput.length !== 0) {
      this.setState({orderedInput: errorInput, Error: true})
    } else {
      this.setState({Submit: true})
    }
  }

  submitUpload = async () => {
    let errorInput = [];
    let counter = 0;
    let elementsToAdd = this.state.orderedInput.length
    for (let i = 0; i < this.state.orderedInput.length; i++) {
      let newUser = this.state.orderedInput[i];
      this.errorCheck(newUser);
      if (newUser.FirstNameError !== "" || newUser.LastNameError !== "" ||
        newUser.DCEError !== "" || newUser.SectionError !== "") {
        errorInput[counter] = newUser;
        counter++;
        if (i+1===elementsToAdd) {
          this.resolveData(errorInput);
        }
      } else {
        await this.sendData(newUser, i)
        .then(result => {
          if (i+1===elementsToAdd) {
            this.resolveData(errorInput);
          }
        })
        // eslint-disable-next-line
        .catch(error => {
          errorInput[counter] = newUser;
          counter++;
          if (i+1===elementsToAdd) {
            this.resolveData(errorInput);
          }
        })
      }
    }
  }

  confirm = (index, user) => {
    if (window.confirm("Delete this user?")) {
      var tempArray = this.state.orderedInput;
      for (let i = 0; i < this.state.orderedInput.length; i++) {
        let tempUser = this.state.orderedInput[i];
        if (tempUser.DCE === user.DCE && tempUser.FirstName === user.FirstName
          && tempUser.LastName === user.LastName)
        {
          tempArray.splice(i,1)
          this.setState({orderedInput: tempArray})
          break;
        }
      }
    }
  }

  elementChange = (arrayIndex, elementType, event) => {
    var fixedInput = this.state.orderedInput;
    if (elementType==="FirstName") {
      fixedInput[arrayIndex].FirstName = event.target.value
    }
    else if (elementType==="LastName") {
      fixedInput[arrayIndex].LastName = event.target.value
    }
    else if (elementType==="Section") {
      fixedInput[arrayIndex].Section = event.target.value
    }
    else if (elementType==="DCE") {
      fixedInput[arrayIndex].DCE = event.target.value
      fixedInput[arrayIndex].Email = event.target.value+"@rit.edu"
    }
    this.setState({orderedInput: fixedInput})
  }

  errorCheck = (newUser) => {
    if (!blankCheck(newUser.FirstName)) {
      if (nameCheck(newUser.FirstName)) {
        newUser.FirstNameError = "Invalid Entry"
      } else {
        newUser.FirstNameError = ""
      }
    } else {
      newUser.FirstNameError = "Blank Entry"
    }
    if (!blankCheck(newUser.LastName)) {
      if (nameCheck(newUser.LastName)) {
        newUser.LastNameError = "Invalid Entry"
      } else {
        newUser.LastNameError = ""
      }
    } else {
      newUser.LastNameError = "Blank Entry"
    }
    if (!blankCheck(newUser.DCE)) {
      if (dceCheck(newUser.DCE)) {
        newUser.DCEError = "Invalid Entry"
      } else {
        newUser.DCEError = ""
      }
    } else {
      newUser.DCEError = "Blank Entry"
    }
    if (!blankCheck(newUser.Section)) {
      if (sectionCheck(newUser.Section)) {
        newUser.SectionError = "Invalid Entry"
      } else {
        newUser.SectionError = ""
      }
    } else {
      newUser.SectionError = "Blank Entry"
    }
  }

  readFile = (event) => {
    this.setState({parsing: true});
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      let text = reader.result;
      let rows = text.split('\n');
      let first = true;
      let order = [];
      let counter = 0;
      let reorderedElements = [];
      rows.forEach((element, index) => {
        let rowItems = element.split(",");
        if (first) {
          //Going to create an array to map rows to their proper values
          //This fixes the data so no matter what order the columns are in,
          //the order the system expects it to be in is kept
          for (let i = 0; i < rowItems.length; i++) {
            rowItems[i] = rowItems[i].replace(/["']+/g, '').replace(/\s/g, '').trim()
            if (rowItems[i].toLowerCase() === "firstname") {
              order[0] = i;
            } else if (rowItems[i].toLowerCase() === "lastname") {
              order[1] = i;
            } else if (rowItems[i].toLowerCase() === "dce") {
              order[2] = i;
            }
            else if (rowItems[i].toLowerCase() === "section") {
              order[3] = i;
            }
          }
          first = false;
          if (order.length === 0) {
            alert("Please make sure the file is a CSV and has column headers");
            return
          }
        }
        else if (rowItems[0] !== "") {
          rowItems[order[0]] = rowItems[order[0]].replace(/["']+/g, '').replace(/\s/g, '').trim()
          rowItems[order[1]] = rowItems[order[1]].replace(/["']+/g, '').replace(/\s/g, '').trim()
          rowItems[order[2]] = rowItems[order[2]].replace(/["']+/g, '').replace(/\s/g, '').trim()
          rowItems[order[3]] = rowItems[order[3]].replace(/["']+/g, '').replace(/\s/g, '').trim().replace(/\w\S*/g, function(text){ return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase() })
          const newUser = {
            FirstName: rowItems[order[0]],
            LastName: rowItems[order[1]],
            Email: rowItems[order[2]]+"@rit.edu",
            DCE: rowItems[order[2]],
            Role: "M",
            Section: rowItems[order[3]],
            Status: "F",
            Retained: 0,
            Exempt: 0,
            ExemptDesc: "",
            Edit: false,
            FirstNameError: "",
            LastNameError: "",
            DCEError: "",
            SectionError: ""
          }
          this.errorCheck(newUser)
          reorderedElements[counter] = newUser;
          counter++;
        }
      });
      this.setState({orderedInput: reorderedElements, parsing: false, finished: true})
    }
    reader.readAsText(file);
  }

  render() {
    return (
      <div>
        {this.state.Submit?
          <Redirect to={'/secretary/users'} />
          :
          null
        }
        {this.state.Error?
          <h2>The following elements could not be added. Please check to ensure they don't already exist</h2>
          :
          null
        }
        <h3>File Upload</h3>
        <div className='secBarNav'>
          <Link
            to='/secretary/home'
            className='secBarLink'
          >
            <p className='secBarText'>Back to "Secretary Home"</p>
            </Link>
          <Link
            to='/secretary/users'
            className='secBarLink'
          >
            <p className='secBarText' style={{marginLeft: "10px"}}>Go to Users</p>
          </Link>
        </div>
        <hr />
        <div>
          <div className='oneline'>
            <p className="fileInputTag">Select a CSV file to upload:
              <input type="file" className="fileInput" onChange={this.readFile} />
            </p>
            {this.state.parsing?
              <div className="loader"></div>
              :
              null
            }
          </div>
          {this.state.finished?
            <div>
              <div className='oneline'>
                <p className='fileInfo'>Elements have finished loading!</p>
                <p className='fileInfo' style={{marginLeft: '3px'}}>Please review the following entries and make any changes.</p>
              </div>
              <div className='oneline'>
                <p className='fileInfo'>When done, please hit the submit button.</p>
                <button className='fileSubmit' onClick={this.submitUpload}>Submit</button>
              </div>
            </div>
            :
            null
          }
          </div>
          <div className='tableDiv'>
            <table className='userTable'>
              <thead>
                <tr className='userRow userHeaderRow'>
                  <th className='userHeader'>First Name</th>
                  <th className='userHeader'>Last Name</th>
                  <th className='userHeader'>DCE</th>
                  <th className='userHeader'>Section</th>
                  <th className='userHeader'>Delete</th>
                </tr>
              </thead>
              <tbody>
                {this.state.orderedInput.map((user, index) =>
                  <tr key={index} className='userRow'>
                    <td className='userEntry'>
                      {this.state.orderedInput[index].FirstNameError===""?
                      <input
                        type='text'
                        onChange={(e) => this.elementChange(index, "FirstName", e)}
                        value={this.state.orderedInput[index].FirstName}
                      />
                      :
                      <div className='tooltip'>
                        <input
                          type='text'
                          style={{background: "yellow"}}
                          onChange={(e) => this.elementChange(index, "FirstName", e)}
                          value={this.state.orderedInput[index].FirstName}
                        />
                        <span className='tooltiptext'>
                          {this.state.orderedInput[index].FirstNameError}
                        </span>
                      </div>
                      }
                    </td>
                    <td className='userEntry'>
                      {this.state.orderedInput[index].LastNameError===""?
                      <input
                        type='text'
                        onChange={(e) => this.elementChange(index, "LastName", e)}
                        value={this.state.orderedInput[index].LastName}
                      />
                      :
                      <div className='tooltip'>
                        <input
                          type='text'
                          style={{background: "yellow"}}
                          onChange={(e) => this.elementChange(index, "LastName", e)}
                          value={this.state.orderedInput[index].LastName}
                        />
                        <span className='tooltiptext'>
                          {this.state.orderedInput[index].LastNameError}
                        </span>
                      </div>
                      }
                    </td>
                    <td className='userEntry'>
                      {this.state.orderedInput[index].DCEError===""?
                      <input
                        type='text'
                        onChange={(e) => this.elementChange(index, "DCE", e)}
                        value={this.state.orderedInput[index].DCE}
                      />
                      :
                      <div className='tooltip'>
                        <input
                          type='text'
                          style={{background: "yellow"}}
                          onChange={(e) => this.elementChange(index, "DCE", e)}
                          value={this.state.orderedInput[index].DCE}
                        />
                        <span className='tooltiptext'>
                          {this.state.orderedInput[index].DCEError}
                        </span>
                      </div>
                      }
                    </td>
                    <td className='userEntry'>
                      {this.state.orderedInput[index].SectionError===""?
                      <select
                        value={this.state.orderedInput[index].Section}
                        onChange={(e) => this.elementChange(index, "Section", e)}
                        className="secretaryInput"
                      >
                        <option default value="---">---</option>
                        <option value="Flutes">Flutes</option>
                        <option value="Clarinets">Clarinets</option>
                        <option value="Saxophones">Saxophones</option>
                        <option value="Trumpets">Trumpets</option>
                        <option value="Mellophones">Mellophones</option>
                        <option value="Tenor Saxophones">Tenor Saxophones</option>
                        <option value="Trombones">Trombones</option>
                        <option value="Tubas">Tubas</option>
                        <option value="Percussion">Percussion</option>
                      </select>
                      :
                      <div className='tooltip'>
                        <select
                          value={this.state.orderedInput[index].Section}
                          style={{background: "yellow"}}
                          onChange={(e) => this.elementChange(index, "Section", e)}
                          className="secretaryInput"
                        >
                          <option default value="---">---</option>
                          <option value="Flutes">Flutes</option>
                          <option value="Clarinets">Clarinets</option>
                          <option value="Saxophones">Saxophones</option>
                          <option value="Trumpets">Trumpets</option>
                          <option value="Mellophones">Mellophones</option>
                          <option value="Tenor Saxophones">Tenor Saxophones</option>
                          <option value="Trombones">Trombones</option>
                          <option value="Tubas">Tubas</option>
                          <option value="Percussion">Percussion</option>
                        </select>
                        <span className='tooltiptext'>
                          {this.state.orderedInput[index].SectionError}
                        </span>
                      </div>
                      }
                    </td>
                    <td className='userEntry'>
                      <FontAwesomeIcon
                        icon="trash-alt"
                        className='faDelete'
                        onClick={() => this.confirm(index, user)}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
      </div>
    );
  }
}

export default withRouter(CSV)
