import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { blankCheck, eventCheck, dateCheck } from '../../Errors';
import { USERID } from '../../App.js';

class EventEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
      ...props.match.params,
      Name: "",
      Date: "",
      Type: "Rehearsal",
      Rehearsal: "Y",
      Error: false,
      Submit: false,
      Edit: false,
      Eid: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.eventID != null && this.state.eventID !== undefined) {
      var url = this.state.constants.hostname + '/api/secretary/event?editEvent=' + this.state.eventID;
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then(data => {
        this.setState({Name: data[0].Name, Date: data[0].DAY,
          Type: data[0].Type, Edit: true, Eid: data[0].Eid
        })
      })
      .catch(error => { console.error(error); })
    }
  }

  onNameChange = (event) => {
    this.setState({Name: event.target.value})
  }

  onDateChange = (event) => {
    this.setState({Date: event.target.value})
  }

  onTypeChange = (event) => {
    this.setState({Type: event.target.value})
  }

  onRehearsalChange = (event) => {
    this.setState({Rehearsal: event.target.value})
  }

  errorCheck = () => {
    if (blankCheck(this.state.Name)) {
      console.error("Blank field: Name")
      alert("Blank field: First Name")
      return false;
    }
    if (eventCheck(this.state.Name)) {
      console.error("Invalid Name: " + this.state.Name);
      alert("Invalid Name: " + this.state.Name + "\nPlease fix input");
      return false;
    }
    if (blankCheck(this.state.Date)) {
      console.error("Blank field: Date")
      alert("Blank field: Date")
      return false;
    }
    if (dateCheck(this.state.Date)) {
      console.error("Date is too far into the past or future");
      alert("Date is too far into the past or future");
      return false;
    }
    if (blankCheck(this.state.Type)) {
      console.error("Blank field: Type")
      alert("Blank field: Type")
      return false;
    }
    return true;
  }

  submitData = () => {
    if(this.errorCheck()) {
      this.setState({Error: false})
      const semester = parseInt(this.state.Date.split("-")[1]);
      const event = {
        Name: this.state.Name,
        Date: this.state.Date,
        Rehearsal: this.state.Rehearsal,
        Semester: semester>=6?"Fall":"Spring",
        Type: this.state.Type,
        Eid: this.state.Eid
      }
      var url = this.state.constants.hostname + '/api/secretary/addEvent';
      if (this.state.Edit) {
        fetch(url, {
          body: JSON.stringify({ event: event }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization':`${localStorage.getItem(USERID)}`
          },
          method: 'PUT',
        })
        .then(res => {
          if (!res.ok) {
            this.setState({Error: true})
            return false;
          }
          else {
            this.setState({Submit: true})
          }
        })
        .catch(error => { console.error(error); })
      } else {
        fetch(url, {
          body: JSON.stringify({ event: event }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization':`${localStorage.getItem(USERID)}`
          },
          method: 'POST',
        })
        .then(res => {
          if (!res.ok) {
            this.setState({Error: true})
            return false;
          }
          else {
            this.setState({Submit: true})
          }
        })
        .catch(error => { console.error(error); })
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.submitData()
  }

  render() {
    return (
      <div>
        {this.state.Submit?
          <Redirect to={`/secretary/events`} />
          :
          null
        }
        {this.state.Error?
          <h2>An Error Occurred: Event Already Exists</h2>
          :
          null
        }
        <h3>Event Information:</h3>
        <Link
          to='/secretary/events'
          className='secBarLink'
        >
          <p className='secBarText'>Back to Events (will NOT save)</p>
        </Link>
        <hr />
        <form className='userForm'>
          <label className='secretaryLabel'>
            Event Name:
            <input
              type="text"
              name="Name"
              onChange={this.onNameChange}
              value={this.state.Name}
              className="secretaryInput"
              maxLength="60"
            />
          </label>
          <label className='secretaryLabel'>
            Event Date:
            <input
              type="datetime-local"
              name="Date"
              className="secretaryInput"
              value={this.state.Date}
              onChange={this.onDateChange}
              min="2019-06-07T00:00"
            />
          </label>
          <label className='secretaryLabel'>
            Event Type:
            <select
              value={this.state.Type}
              onChange={this.onTypeChange}
              className="secretaryInput"
            >
              <option default value="Rehearsal">Rehearsal</option>
              <option value="MHockey">Men's Hockey</option>
              <option value="WHockey">Women's Hockey</option>
              <option value="MSoccer">Men's Soccer</option>
              <option value="WSoccer">Women's Soccer</option>
              <option value="MLax">Men's Lacrosse</option>
              <option value="WLax">Women's Lacrosse</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label className='secretaryLabel'>
            Does this event count as a rehearsal?
            <select
              value={this.state.Rehearsal}
              onChange={this.onRehearsalChange}
              className="secretaryInput"
            >
              <option default value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </label>
          <button
            onClick={this.handleSubmit}
            className='secretarySubmit'
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(EventEdit);
