import React, { Component } from 'react';

export class History1718 extends Component {
    render() {
        return (
          <div className="History">
              <h2>History 2017-2018</h2>
              <h2>E-Board:</h2>
                <p><b>President: </b>Andrew Zgoda</p>
                <p><b>Vice President: </b>Jacob Cozzarin</p>
                <p><b>Vice President: </b>Bao Thai</p>
                <p><b>Secretary: </b>Sarah Messner</p>
                <p><b>Treasurer: </b>Melissa Barton</p>
                <p><b>Conductor: </b>Megan Detwiler</p>
                <p><i>Conductor Attire:</i> Glasses?</p>

              <h3>Accomplishments and Achievements</h3>
                <p>RPI visited us for the Battle of the Bands and MEGA BAND</p>
                <p>Played for a St. Bonaventure vs Vermont Basketball Game at Blue Cross Arena</p>
                <p>Completed initiative to buy an instrument for every section</p>
                <p>Repaired band owned instruments, including bent mellophones and moldy tubas</p>
                <p>Rochester Americans game at GPC (9/29/17)</p>
                <p>Rochester Roller Derby (3/31/17)</p>
                <p>Painted the mural</p>
                <p>Played at longest hockey Game in RIT Hockey history (~111min of game time) 3/3/2018</p>
                <p>First Female conductor</p>
                <p><b>NEW RECORD:</b> Max Attendance at Brick City Homecoming: 140</p>
                <p><b>NEW RECORD:</b> Largest Section Attendance at Brick City Homecoming: Trumpets with 31 players</p>
                <p><b>NEW RECORD:</b> Max Attendance at GPC: 101, RIT vs RPI 10/21/17</p>
                <p><b>NEW RECORD:</b> Largest Section Attendance to date at GPC: Trumpets with 27 players at RIT vs RPI 10/21/17</p>
                <p><b>Max Attendance at an Event: 140</b></p>
            </div>
        );
    }
}
