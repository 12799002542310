import React, { Component } from 'react';
import '../../css/conductor.css'
import { USERID } from '../App.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

export class Conductor extends Component {
  constructor(props) {
    super(props)
    this.state = {...this.props.state, songs:[], filteredSongs: [] };
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/music';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        return res.json();
      })
      .then(data => {
        if (!data) {
          return;
        }
        this.setState({ songs : data, filteredSongs: data });
      })
      .catch(error => {
        console.error(error);
      });
  }

  statusAll = (status) => {
    var url = this.state.constants.hostname + '/api/music/statusAll';
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type':'application/json',
        'Authorization':`${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({status: status})
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.text();
    })
    .catch(error => {
      console.error(error);
    });
    alert('All songs set to ' + status);
    var updatedSongs = this.state.songs;
    updatedSongs.forEach((item, index, arr) => {
      item.Status = status;
    });
    this.setState({songs: updatedSongs});
  }
  render() {
    return (
      <div id="conductor">
        <h2 className="conductorTitle">Conductor</h2>
          <div className="conductorDiv">
            <table className="statusAllTable">
              <tbody>
                <tr>
                  <td>
                    <input type="button" className="songStatusButton statusAll allPublic" value="All Public"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to make all songs public?')) {
                        this.statusAll('Public');
                      }
                    }}/>
                  </td>

                  <td>
                    <input type="button" className="songStatusButton statusAll allPrivate" value="All Private"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to make all songs private?')) {
                        this.statusAll('Private');
                      }
                    }}/>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="conductorTable">
              <thead>
                <tr className="conductorRow">
                  <th className="conductorHeader">Song</th>
                  <th className="conductorHeader">Status</th>
                  <th className="conductorHeader">Comments</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.songs.map(song =>
                    <ConductorEntry key={song.Slug} state={this.state} song={song} />
                  )
                }
              </tbody>
            </table>
        </div>
      </div>
    );
  }
}

export class ConductorEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      song: this.props.song,
      initalComment: this.props.song.Comment,
      needSave: false
    };
  }

  commentChange = (event) => {
    let tempSong = this.state.song;
    tempSong.Comment = event.target.value;
    if (this.state.initalComment !== tempSong.Comment) {
      this.setState({song: tempSong, needSave: true})
    } else {
      this.setState({song: tempSong, needSave: false})
    }
  }

  saveComment = () => {
    var url = this.state.constants.hostname + '/api/music/applyComment';
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type':'application/json',
        'Authorization':`${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({song: this.state.song})
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      this.setState({needSave: false})
    })
    .catch(error => {
      console.error(error);
    });
  }

  toggleStatus = () => {
    var url = this.state.constants.hostname + '/api/music/toggleStatus';
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type':'application/json',
        'Authorization':`${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({song: this.props.song})
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.text();
    })
    .then(data => {
      if (!data) {
        return;
      }
      var changedSong = this.props.song;
      changedSong.Status = data;
      this.setState({song: changedSong});
    })
    .catch(error => {
      console.error(error);
    });
  }

  render () {
    return (
      <tr className='conductorRow'>
        <td className='conductorData'>
          <div key={this.props.song.Slug} className='songLink'>
          <Link
            to={"/music/" + this.props.song.Slug}
            className='songColor'
          >
            <FontAwesomeIcon icon="file-alt" className='faFile'/>
            {this.props.song.Slug}
          </Link>
          </div>
        </td>

        <td className='conductorData'>
          <input type="button" className={'songStatusButton status' + this.state.song.Status} value={this.state.song.Status} onClick={this.toggleStatus}/>
        </td>
        <td className='conductorData'>
          <div className='oneline commentSave'>
            <input
              type='text'
              className='conductorInput'
              onChange={this.commentChange}
              value={this.state.song.Comment}
              placeholder='Jimmy a comment in here..'
              maxLength="500"
            />
            {this.state.needSave?
              <FontAwesomeIcon
                icon="save"
                className='faSave'
                onClick={this.saveComment}
              />
              :
              null
            }
          </div>
        </td>
      </tr>
    );
  }
}
