import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { authRole } from "../../index.js";

import { Home } from './QdbPages/Home.js';
import AddQuote from './QdbPages/AddQuote.js';
import { ApproveQuote } from './QdbPages/ApproveQuote.js';

export class Qdb extends Component {
  constructor(props) {
    super(props)
    this.state=this.props.state;
  }

  render() {
    return (
      <div id="Qdb">
        <Switch>
          <Route path='/qdb/home' component={() => <Home state={this.state} />}/>
          <Redirect exact from='/qdb' to='/qdb/home'/>
          <Route path='/qdb/addQuote' component={() => <AddQuote state={this.state} />}/>
          {authRole(this.props.state.userRole, this.state.constants.roles.president)?
            <Route
              path='/qdb/approveQuote'
              component={() => <ApproveQuote state={this.state} />}
            />
            :
            <Redirect to='/404' />
          }
          <Redirect to='/404' />
        </Switch>
      </div>
    );
  }
}
