import React, { Component } from "react";
import { USERID } from "../../App";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router-dom";

export class Results extends Component{
    constructor(props) {
        super(props)
        this.state = {
            ...this.props.state,
            totalGoodStanding: 0,
            quorums: [],
            voteTallies: []
        }
    }

    componentDidMount() {
        var url = this.state.constants.hostname + '/api/voting/results';
        fetch(url, {
            headers: {'Authorization':`${localStorage.getItem(USERID)}`}
        })
        .then(res => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then(data => {
          if (!data) { return; }
          this.setState({ totalGoodStanding : data.totalGoodStanding[0].numGoodStanding,
                          voteTallies: data.votes,
                          quorums: data.quorums});
        })
        .catch(error => { console.log(error); });
    }

    render() {
        return (
            <div>
                <Link
                    to='/vote'
                    className='secBarLink'
                >
                    <p className='secBarText'>Back to "Election Home"</p>
                </Link>
                <h3>Results</h3>
                <p>Total Good Standing Members: {this.state.totalGoodStanding}</p>
                <p>Votes required to hit Quorom: {Math.ceil(this.state.totalGoodStanding / 2)}</p>
                <h4>Quoroms</h4>
                <table>
                    <tr>
                        <th>Election</th>
                        <th>Votes</th>
                    </tr>
                    {this.state.quorums.map(q => 
                        <tr>
                            <td>{q.ElectionName}</td>
                            <td style={{'text-align': 'center'}}>{q.votedCount}</td>
                        </tr>
                    )}
                </table>

                <h4>Vote Tallies: </h4>
                <table>
                    <tr>
                        <th>Election</th>
                        <th>Candidate</th>
                        <th>"Points"</th>
                        <th>Abstains</th>
                    </tr>
                    {this.state.voteTallies.map(v => 
                        <tr>
                            <td>{v.ElectionName}</td>
                            <td>{v.name}</td>
                            <td style={{'text-align': 'center'}}>{v.PointsEarned}</td>
                            <td style={{'text-align': 'center'}}>{v.ZeroPointsVotes}</td>
                        </tr>
                    )}
                </table>
            </div>
        )
    }
}

export default withRouter(Results)