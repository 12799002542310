import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { USERID } from '../App.js'

class Show extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      ...props.match.params,
    }
  }

  componentDidMount() {
    document.title = this.state.title + " – " + this.state.file;
  }

  render() {
    var url = this.state.constants.hostname + '/api/music/display?title=' + this.state.title + "&file=" + this.state.file + "&user=" + localStorage.getItem(USERID);
    if (this.state.file.split(".")[1] === 'jpg') {
      return (
        <div id="show">
          <img
            className='song'
            src={url}
            alt={this.state.title + " – " + this.state.file}
          />
        </div>
      )
    }
    return (
      <div id="show">
        <iframe
          className='song pdf'
          src={url}
          frameBorder='no'
          title={this.state.title}
          name={this.state.title}
        >
        </iframe>
      </div>
    );
  }
}

export default withRouter(Show)
