import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App.js';

class UserToEvent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      Error: false,
      Submit: false,
      users: [],
      filteredUsers: [],
      usersGoing: [],
      selectedUser: "",
      userDCEGoing: [],
      events: [],
      eventID: 0
    }
    this.addUserGoing = this.addUserGoing.bind(this);
    this.section = "";
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/secretary/users?user=' + this.state.user.uid;
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then(data => {
        if (!data) { return; }
        this.setState({ users : data, filteredUsers: data });
    })
    .catch(error => { console.error(error); });
    url = this.state.constants.hostname + '/api/secretary/events?user=' + this.state.user.uid;
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then(data => {
        if (!data) { return; }
        this.setState({ events : data });
    })
    .catch(error => { console.error(error); });
  }

  confirm = (user, index) => {
    if (window.confirm("Delete this user from the event attendance list?")) {
      var tempArray = this.state.usersGoing;
      tempArray.splice(index, 1)
      var tempArray2 = this.state.userDCEGoing;
      tempArray2.splice(user, 1)
      this.setState({usersGoing: tempArray, userDCEGoing: tempArray2})
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let errorInput = [];
    let errorDCEInput = [];
    let counter = 0;
    let elementsToAdd = this.state.usersGoing.length
    this.state.usersGoing.forEach((newUser, idx) => {
      var url = this.state.constants.hostname + '/api/secretary/addAttendant';
      fetch(url, {
        body: JSON.stringify({ userInfo: newUser.DCE, eventID: this.state.eventID }),
        headers: {
          'Authorization':`${localStorage.getItem(USERID)}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then(res => {
        if ((elementsToAdd - 1) === idx) {
          if (!res.ok) {
            errorInput[counter] = newUser;
            errorDCEInput[counter] = newUser.DCE;
            counter++;
          }
          if (errorInput.length !== 0) {
            this.section="";
            this.setState({userDCEGoing: errorDCEInput, usersGoing: errorInput, Error: true})
          } else {
            this.setState({Submit: true})
          }
        }
        else if (!res.ok) {
          throw new Error("Entry already exists within the system")
        }
      })
      .catch(error => {
        console.error(error);
        errorInput[counter] = newUser;
        errorDCEInput[counter] = newUser.DCE;
        counter++;
      })
    })
  }

  onEventChange = (event) => {
    this.setState({eventID: event.target.value})
  }

  onUserChange = (event) => {
    this.setState({selectedUser: event.target.value})
  }

  addUserGoing = (event) => {
    event.preventDefault();
    for( let user of this.state.users) {
      if (user.DCE === this.state.selectedUser) {
        if (!this.state.userDCEGoing.includes(user.DCE)){
          let tempGoing = this.state.usersGoing;
          let tempDCEGoing = this.state.userDCEGoing;
          tempGoing.push(user);
          tempDCEGoing.push(user.DCE);
          this.section="";
          this.setState({usersGoing: tempGoing, userDCEGoing: tempDCEGoing, selectedUser: ""})
          break;
        }
        else {
          console.error("User already going");
          alert("User already added to event");
          this.setState({selectedUser: ""})
        }
      }
    }
  }

  generateRow(user, index) {
    if (this.section===user.SECTION) {
      return (
        <tr key={user.DCE} className='userRow' style={{cursor: "auto"}}>
          <td className='userEntry'></td>
          <td className='userEntry'>{user.LastName}</td>
          <td className='userEntry'>{user.FirstName}</td>
          <td className='userEntry'>
            <FontAwesomeIcon
              icon="trash-alt"
              className='faDelete'
              onClick={() => this.confirm(user.DCE, index)}
            />
          </td>
        </tr>
      );
    } else {
      this.section=user.SECTION;
      return (
        <tr key={user.DCE} className='userRow' style={{cursor: "auto"}}>
          <td className='userEntrySeparate'>{user.SECTION}</td>
          <td className='userEntrySeparate'>{user.LastName}</td>
          <td className='userEntrySeparate'>{user.FirstName}</td>
          <td className='userEntry'>
            <FontAwesomeIcon
              icon="trash-alt"
              className='faDelete'
              onClick={() => this.confirm(user.DCE, index)}
            />
          </td>
        </tr>
      );
    }
  }

  render() {
    this.section = undefined;
    return (
      <div>
        {this.state.Submit?
          <Redirect to={'/secretary/events'} />
          :
          null
        }
        {this.state.Error?
          <h2>The following elements could not be added. Please check to ensure they aren't already apart of the event</h2>
          :
          null
        }
        <h3>Add Users to Event</h3>
        <div className='secBarNav'>
          <Link
            to='/secretary/home'
            className='secBarLink'
          >
            <p className='secBarText'>Back to Secretary Home</p>
            </Link>
          <Link
            to='/secretary/events'
            className='secBarLink'
          >
            <p className='secBarText' style={{marginLeft: "10px"}}>Go to Events</p>
          </Link>
        </div>
        <hr />
        <h4>Event Selection</h4>
        <form>
          <label className='secretaryLabel'>
            Event:
            <select
              value={this.state.eventID}
              onChange={this.onEventChange}
              className="secretaryInput"
            >
              <option default value={0}>---</option>
              {
                this.state.events.map((event) =>
                  <option key={event.Eid} value={event.Eid}>{event.Name}</option>
                )
              }
            </select>
          </label>
        </form>
        <h4>User(s) Selection</h4>
        <form>
          <label className='secretaryLabel'>
            User:
            <input
              value={this.state.selectedUser}
              onChange={this.onUserChange}
              className="secretaryInput"
              list="usersList"
            />
            <datalist id="usersList">
              {
                this.state.filteredUsers.map((user) =>
                  <option key={user.DCE} value={user.DCE}>{user.LastName},{user.FirstName}</option>
                )
              }
            </datalist>
            <button
              onClick={this.addUserGoing}
              className={"secretarySelect"}
            >
              Select Attendee
            </button>
          </label>
        </form>
        <h4>Selected Users ({this.state.usersGoing.length})</h4>
        <button
          onClick={this.handleSubmit}
          className='secretarySubmit'
        >
          Submit List
        </button>
        <div className='tableDiv'>
          <table className='userTable'>
            <thead>
              <tr className='userRow userHeaderRow'>
                <th className='userHeader'>Section</th>
                <th className='userHeader'>Last Name</th>
                <th className='userHeader'>First Name</th>
                <th className='userHeader'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {this.state.usersGoing.map((user, index) =>
                this.generateRow(user, index)
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(UserToEvent)
