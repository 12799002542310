import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../App.js'
import '../../css/home.css';


export class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {...this.props.state, songs:[], filteredSongs: [] };
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/music?status=Public';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then(data => {
      if (!data) { return; }
        this.setState({ songs : data, filteredSongs: data });
      })
    .catch(error => { console.error(error); });
  }

  handleSearch = (event) => {
    var filteredSongs = [];
    var idx = 0;
    var searchVal = event.target.value.toLowerCase();
    this.state.songs.filter(song => {
      if (song.Title.toLowerCase().includes(searchVal)) {
        filteredSongs[idx] = song;
        idx++;
      }
      return filteredSongs;
    })
    this.setState({filteredSongs: filteredSongs});
  }

  render() {
    return (
      <div id="Home" className="flex-container">
        <div id="Songs">
          <h3 className='t2'>Music</h3>
          <input
            onChange={this.handleSearch}
            className='songSearch'
            placeholder="Search for songs..."
          />
          <div className='center'>
            <table>
              <tbody>
              {this.state.filteredSongs.map(song =>
                <tr key={song.Slug}>
                  <td>
                    <div className='songLink'>
                      <Link
                        to={"/music/" + song.Slug}
                        className='songColor'
                      >
                        <FontAwesomeIcon icon="file-alt" className='faFile'/>
                        {song.Title}
                      </Link>
                    </div>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
        <div id="Calendar">
          <h3>Calendar</h3>
          <iframe
            title="calendar"
            src="https://calendar.google.com/calendar/embed?src=ritpepband%40gmail.com&ctz=America%2FNew_York&showTitle=0&color=%23B1440E&showPrint=0&showCalendars=0&showTabs=0&showTz=0"
            style={{border: '0'}}
            width="300"
            height="400"
            frameBorder="0"
            scrolling="no"
          >
          </iframe>
        </div>
      </div>
    );
  }
}
