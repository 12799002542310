import React, { Component } from 'react';

export class History1415 extends Component {
    render() {
        return (
          <div className="History">
              <h2>History 2014-2015</h2>
              <h2>E-Board:</h2>
                <p><b>President: </b>Neil Guetin</p>
                <p><b>Vice President: </b>Brian Besmanoff</p>
                <p><b>Vice President: </b>Zach Hankinson</p>
                <p><b>Secretary: </b>Dan Consoloazio</p>
                <p><b>Treasurer: </b>Jon Theismann</p>
                <p><b>Conductor: </b>Tyler Kiddle</p>
                <p><i>Conductor Attire:</i> orange shirt, black pants, black tie, black fedora</p>

              <h3>Accomplishments and Achievements</h3>
                <p>First Hard of Hearing Conductor</p>
                <p>First year in the Gene Polisseni Center</p>
                <p>Started holding rehearsals in the Gene Polisseni Center</p>
                <p>Traveled to Notre Dame for NCAA Regional Games</p>
                <p>Traveled to play playoff games at Mercyhurst for the Women’s Team</p>
                <p>Recorded at Imagine RIT</p>
                <p>Built a new trap case</p>
                <p>Richie fell on the ice and lost his head</p>
                <p>Played for a Rochester Americans Hockey Game</p>
                <p>Got a men’s hockey player to join pep band</p>
                <p><b>Max Attendance at an Event: ??</b></p>
            </div>
        );
    }
}
