import React, { Component } from 'react';
import { USERID } from '../App.js';

export class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      rehearsals: 0,
      events: 0,
      mHockey: 0,
      wHockey: 0,
      mSoccer: 0,
      wSoccer: 0,
      mLax: 0,
      wLax: 0,
      other: 0,
      exempt: false,
      exemptDesc: '',
      FirstName: '',
      LastName: '',
      WGameGo: false,
      MGameGo: false,
      GStanding: false,
      FGStanding: false,
    }
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/attendance';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then(data => {
      this.handleAssign(data)
    })
    .catch(error => { console.error(error); })
    url = this.state.constants.hostname + '/api/exempt';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then(data => {
      if (data[0].Exempt === 1) {
        this.setState({exempt: data[0].Exempt, exemptDesc: data[0].ExemptDesc});
      }
    })
    .catch(error => { console.error(error); })
    url = this.state.constants.hostname + '/api/statuses';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then(data => {
      this.setState({FirstName: data.FirstName, LastName: data.LastName,
        WGameGo: data.WGameGo, MGameGo: data.MGameGo,
        GStanding: data.GStanding, FGStanding: data.FGStanding})
    })
    .catch(error => { console.error(error); })
  }

  handleAssign = (data) => {
    data.forEach((element) => {
      if (element.Rehearsal === 'Y') {
	 this.setState({rehearsals: this.state.rehearsals + element.count});
      }
      switch(element.Type) {
        case 'Rehearsal':
          break;
        case 'MHockey':
          this.setState({mHockey: this.state.mHockey + element.count, events: this.state.events + element.count})
          break;
        case 'WHockey':
          this.setState({wHockey: this.state.wHockey + element.count, events: this.state.events + element.count})
          break;
        case 'MSoccer':
          this.setState({mSoccer: this.state.mSoccer + element.count, events: this.state.events + element.count})
          break;
        case 'WSoccer':
          this.setState({wSoccer: this.state.wSoccer + element.count, events: this.state.events + element.count})
          break;
        case 'MLax':
          this.setState({mLax: this.state.mLax + element.count, events: this.state.events + element.count})
          break;
        case 'WLax':
          this.setState({wLax: this.state.wLax + element.count, events: this.state.events + element.count})
          break;
        case 'Other':
          this.setState({other: this.state.other + element.count, events: this.state.events + element.count})
          break;
        default:
          console.error('Unhandled event type. Please let webmaster know');
      }
    });
  }

  warningController() {
    if (this.state.rehearsals < 3) {
      if (this.state.exempt) {
        return (
          <div>
            <div className='oneline attendanceh3'>
              <h3 className='attendanceh3'>
                You currently have exemption from the following event types:
              </h3>
              <h3 className='attendanceh3' style={{marginLeft: '5px'}}>
                {this.state.exemptDesc}
              </h3>
            </div>
            <h3 className='attendanceh3'>
              This may or may not allow you to attend games.
            </h3>
          </div>
        );
      }
      else if (this.state.rehearsals === 2) {
        return (
          <h3 className='attendanceh3'>
            Warning: You currently cannot attend men's hockey games
          </h3>
        );
      }
      return (
        <h3 className='attendanceh3'>
          Warning: You currently cannot attend men's or women's hockey games
        </h3>
      )
    }
    return null;
  }

  render() {
    return (
      <div id="attendance">
        <h1>
          Attendance for: {this.state.LastName}, {this.state.FirstName}
        </h1>
        {this.warningController()}
        <div>
          <h3 className='attendanceh3'>
            Rehearsals: {this.state.rehearsals}
          </h3>
          <h3 className='attendanceh3'>
            Events: {this.state.events}
          </h3>
          <h3 className='attendanceh3'>
            Good Standing (Current Semester): {this.state.GStanding===1?"True":"False"}
          </h3>
          <h3 className='attendanceh3'>
            Projected Good Standing (For Future Semester): {this.state.FGStanding===1?"True":"False"}
          </h3>
          <p>Men's Hockey Games: {this.state.mHockey}</p>
          <p>Women's Hockey Games: {this.state.wHockey}</p>
          <p>Men's Soccer Games: {this.state.mSoccer}</p>
          <p>Women's Soccer Games: {this.state.wSoccer}</p>
          <p>Men's Lacrosse Games: {this.state.mLax}</p>
          <p>Women's Lacrosse Games: {this.state.wLax}</p>
          <p>Other events: {this.state.other}</p>
        </div>
        <br/>
        <div>
          <p>
            *Disclaimer: These may not be up to date with the latest events*
          </p>
          <p>
            *If something doesn't look right please contact the secretary*
          </p>
          <p>
            *Good Standing calculations can be found in the constitution*
          </p>
        </div>
      </div>
    );
  }
}
