import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { History0607 } from './HistoryPages/2006-2007.js';
import { History0708 } from './HistoryPages/2007-2008.js';
import { History0809 } from './HistoryPages/2008-2009.js';
import { History0910 } from './HistoryPages/2009-2010.js';
import { History1011 } from './HistoryPages/2010-2011.js';
import { History1112 } from './HistoryPages/2011-2012.js';
import { History1213 } from './HistoryPages/2012-2013.js';
import { History1314 } from './HistoryPages/2013-2014.js';
import { History1415 } from './HistoryPages/2014-2015.js';
import { History1516 } from './HistoryPages/2015-2016.js';
import { History1617 } from './HistoryPages/2016-2017.js';
import { History1718 } from './HistoryPages/2017-2018.js';
import { History1819 } from './HistoryPages/2018-2019.js';
import { History1920 } from './HistoryPages/2019-2020.js';
import { History2021 } from './HistoryPages/2020-2021.js';
import { History2122 } from './HistoryPages/2021-2022.js';
import { History2223 } from './HistoryPages/2022-2023.js';
import { History2324 } from './HistoryPages/2023-2024.js';
import { NotFound } from './NotFound.js';
import '../../css/history.css'


export class History extends Component {
  render() {
    return (
      <div id="HistoryList">
          <div>
            <ul className='historyController'>
              <Link to='/history/2006-2007' id="historyLink"><li>2006-2007</li></Link>
              <Link to='/history/2007-2008' id="historyLink"><li>2007-2008</li></Link>
              <Link to='/history/2008-2009' id="historyLink"><li>2008-2009</li></Link>
              <Link to='/history/2009-2010' id="historyLink"><li>2009-2010</li></Link>
              <Link to='/history/2010-2011' id="historyLink"><li>2010-2011</li></Link>
              <Link to='/history/2011-2012' id="historyLink"><li>2011-2012</li></Link>
              <Link to='/history/2012-2013' id="historyLink"><li>2012-2013</li></Link>
              <Link to='/history/2013-2014' id="historyLink"><li>2013-2014</li></Link>
              <Link to='/history/2014-2015' id="historyLink"><li>2014-2015</li></Link>
              <Link to='/history/2015-2016' id="historyLink"><li>2015-2016</li></Link>
            </ul>
          </div>
          <div>
            <ul className='historyController'>
              <Link to='/history/2016-2017' id="historyLink"><li>2016-2017</li></Link>
              <Link to='/history/2017-2018' id="historyLink"><li>2017-2018</li></Link>
              <Link to='/history/2018-2019' id="historyLink"><li>2018-2019</li></Link>
              <Link to='/history/2019-2020' id="historyLink"><li>2019-2020</li></Link>
              <Link to='/history/2020-2021' id="historyLink"><li >2020-2021</li></Link>
              <Link to='/history/2021-2022' id="historyLink"><li >2021-2022</li></Link>
              <Link to='/history/2022-2023' id="historyLink"><li >2022-2023</li></Link>
              <Link to='/history/2023-2024' id="historyLink"><li >2023-2024</li></Link>
            </ul>
          </div>
          <Switch>
          <Redirect exact from='/history' to='/history/2019-2020'/>
          <Route path='/history/2006-2007' component={History0607}/>
          <Route path='/history/2007-2008' component={History0708}/>
          <Route path='/history/2008-2009' component={History0809}/>
          <Route path='/history/2009-2010' component={History0910}/>
          <Route path='/history/2010-2011' component={History1011}/>
          <Route path='/history/2011-2012' component={History1112}/>
          <Route path='/history/2012-2013' component={History1213}/>
          <Route path='/history/2013-2014' component={History1314}/>
          <Route path='/history/2014-2015' component={History1415}/>
          <Route path='/history/2015-2016' component={History1516}/>
          <Route path='/history/2016-2017' component={History1617}/>
          <Route path='/history/2017-2018' component={History1718}/>
          <Route path='/history/2018-2019' component={History1819}/>
          <Route path='/history/2019-2020' component={History1920}/>
          <Route path='/history/2020-2021' component={History2021}/>
          <Route path='/history/2021-2022' component={History2122}/>
          <Route path='/history/2022-2023' component={History2223}/>
          <Route path='/history/2023-2024' component={History2324}/>
          <Route component={NotFound}/>
        </Switch>
      </div>
    );
  }
}
