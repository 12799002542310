import React, { Component } from 'react';

export default class Footer extends Component {
  render(){
    return (
      <div className='footer'>
        <p className='center footText'>Updated for the 2024-2025 Academic Year</p>
        <p className='center footText'>For Use by the RIT Pep Band Only</p>
      </div>
    );
  }
}
