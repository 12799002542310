import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App.js';

export class Event extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
      ...props.match.params,
      Name: "",
      Date: "",
      Type: "",
      Eid: "",
      Rehearsal: "",
      eventEdit: false,
      delete: false,
      attendees: []
    };
    this.handleClick = this.handleClick.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  handleClick() {
    this.setState({eventEdit: true});
  }

  handleDownload() {
    let csvContent = "data:text/csv;charset=utf-8,"
      + this.state.attendees.map(e => `${e.SECTION},${e.LastName},${e.FirstName}`).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    let now = new Date();
    link.setAttribute("download", `Attendance-${this.state.Type}-${this.state.Date}-(${now.getMonth()+1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }

  confirm() {
    if (window.confirm("Delete this event?")) {
      var url = this.state.constants.hostname + '/api/secretary/removeEvent?eventInfo=' + this.state.Eid;
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`},
        method: 'DELETE',
      })
      .then(res => {
        this.setState({delete: true})
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .catch(error => { console.error(error); });
    }
  }

  componentDidMount() {
    this.section = undefined;
    if (this.state.eventID != null && this.state.eventID !== undefined) {
      var url = this.state.constants.hostname + '/api/secretary/event?editEvent=' + this.state.eventID;
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then(data => {
        this.setState({Name: data[0].Name, Date: data[0].DAY,
          Type: data[0].Type, Eid: data[0].Eid, Rehearsal: data[0].Rehearsal
        })
      })
      .catch(error => { console.error(error); })
    }
    url = this.state.constants.hostname + '/api/secretary/eventAttendance?eventID=' + this.state.eventID;
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then(data => {
      this.setState({ attendees: data })
    })
    .catch(error => { console.error(error); })
  }

  generateRow(user) {
    if (this.section===user.SECTION) {
      return (
        <tr key={user.DCE} className='userRow' style={{cursor: "auto"}}>
          <td className='userEntry'></td>
          <td className='userEntry'>{user.LastName}</td>
          <td className='userEntry'>{user.FirstName}</td>
        </tr>
      );
    } else {
      this.section=user.SECTION;
      return (
        <tr key={user.DCE} className='userRow' style={{cursor: "auto"}}>
          <td className='userEntrySeparate'>{user.SECTION}</td>
          <td className='userEntrySeparate'>{user.LastName}</td>
          <td className='userEntrySeparate'>{user.FirstName}</td>
        </tr>
      );
    }
  }

  render() {
    this.section = undefined;
    return (
      <div>
        {this.state.eventEdit?
          <Redirect to={`/secretary/eventEdit/${this.state.Eid}`} />
          :
          null
        }
        {this.state.delete?
          <Redirect to='/secretary/events' />
          :
          null
        }
        <h3>Event Information for {this.state.Name}</h3>
        <div className='secBarNav'>
          <Link
            to='/secretary/events'
            className='secBarLink'
          >
            <p className='secBarText'>Back to Events</p>
          </Link>
          <Link
            to='/secretary/userToEvent'
            className='secBarLink'
            style={{marginLeft: "8px"}}
          >
            <p className='secBarText'>Add Users to an Event</p>
          </Link>
          <FontAwesomeIcon
            icon="user-edit"
            className='faEdit'
            onClick={() => this.handleClick()}
          />
          <FontAwesomeIcon
            icon="file-download"
            className='faEdit'
            style={{marginLeft: "0px"}}
            onClick={() => this.handleDownload()}
          />
          <FontAwesomeIcon
            icon="trash-alt"
            className='faDelete'
            onClick={() => this.confirm()}
          />
        </div>
        <hr />
        <div className='userProfile'>
          <label className='secretaryLabel'>
            Name: {this.state.Name}
          </label>
          <label className='secretaryLabel'>
            Date: {this.state.Date}
          </label>
          <label className='secretaryLabel'>
            Type: {this.state.Type}
          </label>
          <label className='secretaryLabel'>
            Count as Rehearsal? {this.state.Rehearsal==="Y"?"Yes":"No"}
          </label>
        </div>
        <h4>Individuals In Attendance ({this.state.attendees.length}):</h4>
        <div className='tableDiv'>
          <table className='userTable'>
            <thead>
              <tr className='userRow userHeaderRow'>
                <th className='userHeader'>Section</th>
                <th className='userHeader'>Last Name</th>
                <th className='userHeader'>First Name</th>
              </tr>
            </thead>
            <tbody>
              {this.state.attendees.map(user =>
                this.generateRow(user)
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(Event)
