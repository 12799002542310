import React, { Component } from 'react';

export default class Loading extends Component {
  render() {
    return (
      <div>
        <h1 className='center'>Easy there, tiger!</h1>
        <h2 className='center'>Gotta take a moment to thonk about that one</h2>
        <div className='center'>
          <img className='thonkTiger rotate' src='/media/thonkingtiger.png'
            alt="Thonk tiger is thinking..."/>
          <img className='thonkTiger rotate' src='/media/thonkingtiger.png'
            alt="Thonk tiger is thinking..."/>
          <img className='thonkTiger rotate' src='/media/thonkingtiger.png'
            alt="Thonk tiger is thinking..."/>
        </div>
        <h4 className='center'>
          If this problem persists, refresh the page or contact the admin
        </h4>
      </div>
    );
  }
}
