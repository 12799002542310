import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom';
import { USERID } from '../../App.js';

export class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {...this.props.state, users:[], filteredUsers:[], userPage: ""};
    this.section = "";
  }

  componentDidMount() {
      var url = this.state.constants.hostname + '/api/secretary/users';
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
      .then(res => {
          if (!res.ok) {
              return false;
          }
          return res.json();
      })
      .then(data => {
          if (!data) { return; }
          this.setState({ users : data, filteredUsers: data });
      })
      .catch(error => { console.error(error); });
  }

  handleDownload() {
    let csvContent = "data:text/csv;charset=utf-8,"
      + this.state.users.map(e => `${e.SECTION},${e.LastName},${e.FirstName},${e.DCE}`).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    let now = new Date();
    link.setAttribute("download", `Users-(${now.getMonth()+1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }

  rowClick(DCE) {
    this.setState({userPage: DCE});
  }

  handleSearch = (event) => {
    var filteredUsers = [];
    var idx = 0;
    var searchVal = event.target.value.toLowerCase();
    this.state.users.filter(user => {
      if (user.DCE.toLowerCase().includes(searchVal)
      || user.FirstName.toLowerCase().includes(searchVal)
      || user.LastName.toLowerCase().includes(searchVal) ) {
        filteredUsers[idx] = user;
        idx++;
      }
      return filteredUsers;
    })
    this.setState({filteredUsers: filteredUsers});
    this.section = null;
  }

  generateRow(user) {
    if (this.section===user.SECTION) {
      return (
        <tr key={user.DCE} className='userRow' onClick={() => this.rowClick(user.DCE)}>
          <td className='userEntry'></td>
          <td className='userEntry'>{user.LastName}</td>
          <td className='userEntry'>{user.FirstName}</td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.WGameGo===1?"True":"False"}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.MGameGo===1?"True":"False"}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.GStanding===1?"True":"False"}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.FGStanding===1?"True":"False"}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
        </tr>
      );
    } else {
      this.section=user.SECTION;
      return (
        <tr key={user.DCE} className='userRow' onClick={() => this.rowClick(user.DCE)}>
          <td className='userEntrySeparate'>{user.SECTION}</td>
          <td className='userEntrySeparate'>{user.LastName}</td>
          <td className='userEntrySeparate'>{user.FirstName}</td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.WGameGo===1?"True":"False"}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.MGameGo===1?"True":"False"}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.GStanding===1?"True":"False"}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
          <td className='userEntrySeparate'>
            <div className='tooltip'>
              {user.FGStanding===1?"True":"False"}
              <span className='tooltiptext'>
                {user.SECTION}, {user.LastName}
              </span>
            </div>
          </td>
        </tr>
      );
    }
  }

  render() {
    return (
      <div>
        {this.state.userPage !== "" ?
          <Redirect to={`/secretary/user/${this.state.userPage}`} />
          :
          null
        }
        <h3>User Information:</h3>
        <div className='secBarNav'>
          <Link
            to='/secretary/home'
            className='secBarLink'
          >
            <p className='secBarText'>Back to "Secretary Home"</p>
          </Link>
          <Link
            to='/secretary/userEdit'
            className='secBarLink'
            state={this.state}
          >
            <div className='oneline'>
              <FontAwesomeIcon icon="plus-circle" className='faAdd'/>
              <p className='secBarText'>
                Add User
              </p>
            </div>
          </Link>
          <FontAwesomeIcon
            icon="file-download"
            className='faEdit'
            onClick={() => this.handleDownload()}
          />
        </div>
        <hr />
        <form>
          <label>
            Search:
            <input
              onChange={this.handleSearch}
              className='userSearch'
            />
          </label>
        </form>
        <div className='tableDiv'>
          <table className='userTable'>
            <thead>
              <tr className='userRow userHeaderRow'>
                <th className='userHeader'>Section</th>
                <th className='userHeader'>Last Name</th>
                <th className='userHeader'>First Name</th>
                <th className='userHeader'>W's Game Eligible</th>
                <th className='userHeader'>M's Game Eligible</th>
                <th className='userHeader'>Good Standing (Current Semester)</th>
                <th className='userHeader'>Good Standing (Future Semester)</th>
              </tr>
            </thead>
            <tbody>
              {this.state.filteredUsers.map(user =>
                this.generateRow(user)
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
