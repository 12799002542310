import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Login } from './Login.js';
import Show from './routes/Show.js'
import NavBar from './NavBar.js';
import Footer from './Footer.js';
import Loading from './Loading.js';
import {blankCheck, passCheck, eventCheck} from './Errors.js'
import { Routes } from './Routes.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faEye, faCrown, faMusic, faUserEdit, faGraduationCap, faUser, faFolderOpen, faAddressCard, faFileAlt, faSignOutAlt, faAlignJustify, faUsers, faPlusCircle, faTrashAlt, faUserPlus, faFolderPlus, faCalendarAlt, faCalendarPlus, faCalendarCheck, faCalendarWeek, faImage, faFileImage, faSave, faCheckSquare, faSync, faClipboard, faFileDownload, faChartBar, faClipboardList, faCheck, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons'
import { firebase, authRole } from "../index.js";
import '../css/main.css';

library.add(faHome, faEye, faCrown, faMusic, faUserEdit, faGraduationCap, faUser, faFolderOpen, faAddressCard, faFileAlt, faSignOutAlt, faAlignJustify, faUsers, faPlusCircle, faTrashAlt, faUserPlus, faFolderPlus, faCalendarAlt, faCalendarPlus, faCalendarCheck, faCalendarWeek, faImage, faFileImage, faSave, faCheckSquare, faSync, faClipboard, faFileDownload, faChartBar, faClipboardList, faCheck, faQuestion, faTimes)

export const USERID = 'user_id'

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      username: "",
      password: "",
      failed: false,
      classic: true,
      loaded: false,
    }
  }

  componentDidMount(){
    if (localStorage.getItem(USERID) !== undefined && localStorage.getItem(USERID) != null) {
      this.props.state.user = 'alumni';
      this.setState({ userRole: "alumni", user: 'alumni' });
      fetch(this.state.constants.hostname + '/api/checkAuth', {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
        .then(res => {
          if (!res.ok) {
            localStorage.removeItem(USERID);
            window.location = '/';
            return false;
          }
          return res.json();
        })
        .then(data => {
          if (!data) { return; }
          this.setState({ userRole: data.role, loaded: true});
        })
    } else {
      firebase.auth().onAuthStateChanged(user => {
        this.handleAuthChange(user);
        this.setState({ user });
      });
    }
  }

  handleAuthChange(user){
    if (user) {
    var url = this.state.constants.hostname + '/api/userRole?userID=' + user.uid + '&email=' + user.email;
    fetch(url)
      .then(res => {
        if (!res.ok) {
          this.setState({ userRole: this.state.constants.roles.nonMember });
          return false;
        }
        return res.json();
      })
      .then(data => {
        if (!data) { return; }
          localStorage.setItem(USERID, data.user);
          this.setState({ userRole: data.role, loaded: true });
      })
      .catch(error => { console.error(error); });
    }
    else {
      this.setState({ userRole: this.state.constants.roles.nonMember });
    }
  }

  errorCheck = () => {
    if (blankCheck(this.state.username)) {
      console.error("Blank field: username")
      alert("Please enter a username")
      return false;
    }
    if (blankCheck(this.state.password)) {
      console.error("Blank field: password")
      alert("Please enter a password")
      return false;
    }
    if (eventCheck(this.state.username)) {
      return false;
    }
    if (passCheck(this.state.password)) {
      return false;
    }
    return true;
  }

  handleLogin = (event) => {
    event.preventDefault();
    var url = this.state.constants.hostname + '/api/alumni/login';
    fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: this.state.username, password: this.state.password })
    })
      .then(res => {
        if (!res.ok) {
          this.setState({ userRole: this.state.constants.roles.nonMember });
          return false;
        }
        return res.json();
      })
      .then(data => {
        if (!data) { return; }
          if (data.role === this.state.constants.roles.nonMember) {
            this.setState({username: "", password: "", failed: true})
          } else {
            this.setState({ userRole: data.role, user: 'alumni' });
            localStorage.setItem(USERID, data.user);
            this.props.state.user = 'alumni'
            this.setState({loaded: true})
          }
      })
      .catch(error => { console.error(error); });
  }

  onUsernameChange = (event) => {
    this.setState({username: event.target.value})
  }

  onPassChange = (event) => {
    this.setState({password: event.target.value})
  }

  switchClassic = () => {
    this.setState({classic: !this.state.classic })
  }

  render() {
    const MainWebsite = () => {
      return (
        <div id="app">
          <div className='content-wrap'>
            <NavBar state={this.state} handleLogout={this.handleLogout}/>
            <div className='body'>
              <div className='bodyInner'>
                <Routes state={this.state}/>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )
    }
    if (!(this.state.userRole)){
      return (null);
    }
    if (!(this.state.user) && (window.location.pathname !== "/chants")){
      return (
        <Login
          message="You are not logged in."
          state={this.state}
          handleLogin={this.handleLogin}
          userChange={this.onUsernameChange}
          passChange={this.onPassChange}
          switchClassic={this.switchClassic}
        />
      );
    }
    if (!(authRole(this.state.userRole, this.state.constants.roles.alumni)) && (window.location.pathname !== "/chants")){
      return (
        <div id="nonMember">
          <Login
            message="You are not a member. Please login using your @g.rit.edu account."
            state={this.state}
          />
        </div>
      );
    }
    if (!(this.state.loaded) && this.state.user && (window.location.pathname !== "/chants")) {
      return (
        <Loading />
      )
    }
    return (
      <Switch>
        <Route exact path='/music/:title/show/:file' component={() => <Show state={this.state}/>}/>
        <MainWebsite />
      </Switch>
    );
  }
}

export default App;
