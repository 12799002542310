import React, { Component } from "react";
import { USERID } from "../../App";
import { withRouter, Link, Redirect } from "react-router-dom";

export class Election extends Component{
    constructor(props) {
        super(props)
        this.state = {
            ...this.props.state,
            election: "",
            abstainable: "Y",
            options: [],
            ordering: [],
            unselected: [],
            hasVoted: "",
            abstain: false
        }
    }

    componentDidMount() {
        const electionName = window.location.href.split('/')[5]
        this.setState({title: electionName})
        var url = this.state.constants.hostname + '/api/voting/hasVoted?electionName=' + electionName;
        fetch(url, {
            headers: {'Authorization':`${localStorage.getItem(USERID)}`}
        })
        .then(res => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then(data => {
          if (!data) { return; }
          let userVotes = data
          this.setState({hasVoted: false})
          for(let i = 0; i < userVotes.length; i++) {
            if(userVotes[i].election_id === electionName) {
                this.setState({hasVoted: true})
                break
            }
          }
        })
        .catch(error => { console.error(error); });

        if(this.state.hasVoted) {
            if (this.state.hasVoted()) {
                window.alert("You have already voted in this Election, Redirecting...")
            }
        }

        // Get Candidates
        url = this.state.constants.hostname + '/api/voting/electionCandidates?electionName=' + electionName;
        fetch(url, {
            headers: {'Authorization':`${localStorage.getItem(USERID)}`}
        })
        .then(res => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then(data => {
          if (!data) { return; }
          let initUnselected = []
          for(let i = 0; i < data.length; i++) {
            initUnselected = initUnselected.concat([{candidate_id: data[i].candidate_id, election_id: data[i].election_id, name: data[i].name}])
          }
          this.setState({options : data, unselected: initUnselected})
        })
        .catch(error => { console.error(error); });

        // Get Election
        url = this.state.constants.hostname + '/api/voting/election?electionName=' + electionName;
        fetch(url, {
            headers: {'Authorization':`${localStorage.getItem(USERID)}`}
        })
        .then(res => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then(data => {
          if (!data) { return; }
          this.setState({election: data[0], abstainable: data[0].abstainable})
        })
        .catch(error => { console.error(error); });
    }

    handleAddToOrder = (event) => {
        if(this.state.options.length === 2 && this.state.ordering.length === 1) {
            window.alert("You can only select one candidate")
        }
        else {
            let newOrdering = this.state.ordering
            let newUnselected = this.state.unselected
            for(let i = 0; i < newUnselected.length; i++) {
                console.log(newUnselected[i])
                // eslint-disable-next-line
                if(newUnselected[i].candidate_id == event.target.id) {
                    newOrdering = newOrdering.concat(newUnselected.splice(i, 1))
                    break
                }
            }
            this.setState({ordering: newOrdering, unselected: newUnselected})
        }
    }

    handleClearOrder = (event) => {
        let initUnselected = []
          for(let i = 0; i < this.state.options.length; i++) {
            initUnselected = initUnselected.concat([{candidate_id: this.state.options[i].candidate_id, election_id: 
                                                this.state.options[i].election_id, name: this.state.options[i].name}])
          }
        this.setState({ordering: [], unselected: initUnselected})
    }

    convertOrderingToIDs() {
        let newOrdering = []
        for(let i = 0; i < this.state.ordering.length; i++) {
            newOrdering = newOrdering.concat(this.state.ordering[i].candidate_id)
        }
        this.setState({ordering: newOrdering})
    }

    handleAbstain = (event) => {
        if(window.confirm("Are you sure you want to abstain?")) {
            this.setState({ordering: []})
            this.handleSubmit(event)
        }
    }

    handleVote = (event) => {
        if(window.confirm("Are you sure you want to vote?")) {
            this.convertOrderingToIDs()
            this.handleSubmit(event)
        }
    }

    handleSubmit = (event) => {
        this.setState({Error: false})
        if(this.state.election.abstainable === "N" && 
           this.state.ordering.length != this.state.options.length &&
           this.state.options.length !== 2) {
            window.alert("You must vote for EVERY candidate in this Election")
            this.handleClearOrder()
            return
        }
        else {
            var url = this.state.constants.hostname + '/api/voting/castVote';
            fetch(url, {
                body: JSON.stringify({ election_name: this.state.title, 
                                        ordering: this.state.ordering,
                                        options: this.state.options}),
                headers: {
                'Authorization':`${localStorage.getItem(USERID)}`,
                'Content-Type': 'application/json',
                },
                method: 'POST',
            })
            .then(res => {
                if (!res.ok) {
                    this.setState({Error: true})
                    window.alert("An Error Occured, Try again and if it still happens DM the webmaster")
                    console.log(res)
                    return false;
                }
                else {
                    this.setState({Submit: true})
                    window.alert("Successfully Voted!")
                }
            })
            .catch(error => { console.error(error); })
        }
    }

    render() {
        return(
            <div>
                {this.state.Submit?
                    <Redirect to={'/vote'}/>
                    :
                    null
                }
                {this.state.hasVoted?
                    <Redirect to={'/vote'}/>
                    :
                    null
                }
                <Link
                    to='/vote'
                    className='secBarLink'
                >
                    <p className='secBarText'>Back to "Election Home"</p>
                </Link>
                <h3>{this.state.title}</h3>
                <p>Click on Candidates in order of preference, higher indicates better</p>
                {this.state.options.length === 2?
                    <div>
                        <p>This election only has 2 options, select one and press submit.</p>
                        {this.state.election.abstainable === "Y"?
                            <div>
                                <p>Alternatively, you can abstain if you wish.</p>
                            </div>
                            :            
                            null
                        }
                        {this.state.election.abstainable === "N"?
                            <p>You cannot abstain from this election, so you must select a candidate</p>
                            :            
                            null
                        }
                    </div>
                    :
                    null
                }
                {this.state.options.length !== 2?
                    <div>
                        {this.state.election.abstainable === "Y"?
                        <div>
                            <p>If you are opposed to some of the candidates, you may select only the ones you approve of. 
                            Leaving a candidate unselected will count as an abstain, if 1/3 of the band abstains from a candidate, they are prevented from winning the election</p>
                            <p>If you are opposed to all candidates, you can hit abstain</p>
                        </div>
                        :            
                        null
                        }
                        {this.state.election.abstainable === "N"?
                            <p>You cannot abstain from this election, so you must vote for every candidate</p>
                            :            
                            null
                        }
                    </div>
                    : 
                    null
                } 
                <br/>
                {this.state.unselected.map(option =>
                    <button id={option.candidate_id} value={option.name} onClick={this.handleAddToOrder}>{option.name}</button>
                    )}
                <br/>
                <button onClick={this.handleClearOrder}>Clear Selection</button>
                {this.state.ordering.map(order =>
                    <div>
                        <p>{order.name}</p>
                        <br/>
                    </div>
                )}
                <button onClick={this.handleVote}>Submit</button>
                {this.state.election.abstainable === "Y"?
                    <button onClick={this.handleAbstain}>Abstain</button>
                    :
                    null
                }
            </div>
        )
    }
}

export default withRouter(Election)
