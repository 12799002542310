import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { blankCheck, nameCheck, emailCheck, dceCheck } from '../../Errors';
import { USERID } from '../../App.js';

const SECTIONS = [
  {
    name: 'Flutes',
    checked: false,
    disabled: false,
  }, 
  {
    name: 'Clarinets', 
    checked: false,
    disabled: false,
  },
  {
    name: 'Saxophones', 
    checked: false,
    disabled: false,
  },
  {
    name: 'Trumpets', 
    checked: false,
    disabled: false,
  },
  {
    name: 'Mellophones',
    checked: false, 
    disabled: false,
  },
  {
    name: 'TenorSaxophones', 
    checked: false,
    disabled: false,
  },
  {
    name: 'Trombones', 
    checked: false,
    disabled: false,
  },
  {
    name: 'Tubas', 
    checked: false,
    disabled: false,
  },
  {
    name: 'Percussion',
    checked: false,
    disabled: false,
  }];

class UserEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
      ...props.match.params,
      FirstName: "",
      LastName: "",
      Email: "",
      DCE: "",
      queryDCE: "",
      Role: "M",
      Section: "---",
      Status: "---",
      MGStanding: false,
      Exempt: false,
      ExemptDesc: "",
      Submit: false,
      Error: false,
      Edit: false,
      MGStandingTime: 0,
      Extra: false,
      TrueExtra: [],
      TempExtra: JSON.parse(JSON.stringify(SECTIONS)),
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.selectedUser != null && this.state.selectedUser !== undefined) {
      let url =this.state.constants.hostname + '/api/secretary/user?editUser=' + this.state.selectedUser;
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then(data => {
        this.setState({FirstName: data[0].FirstName, LastName: data[0].LastName,
          Email: data[0].Email, DCE: data[0].DCE, queryDCE: data[0].DCE, Role: data[0].Role,
          Section: data[0].SECTION, Status: data[0].Status, MGStanding: data[0].MGStanding,
          Exempt: data[0].Exempt, ExemptDesc: data[0].ExemptDesc, Edit: true,
          MGStandingTime: data[0].MGStandingTime});
      })
      .catch(error => { console.error(error); })
      url = this.state.constants.hostname + '/api/secretary/userSections?user=' + this.state.selectedUser;
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then(data => {
        let localSec = JSON.parse(JSON.stringify(SECTIONS));
        data.forEach(section => {
          localSec.forEach(innerSec => {
            if (innerSec.name === section.SECTION) {
              innerSec.checked = true;
            }
          });
        });
        const sec = localSec.find(section => {
          return section.name === this.state.Section
        });
        if (sec !== undefined) {
          sec.disabled = true;
        }
        this.setState({TempExtra: localSec});
        let convert = data.map(section => {
          return {name: section.SECTION, checked: true, disabled: false}
        });
        this.setState({TrueExtra: convert});
        if (convert.length !== 0) {
          this.setState({Extra: true});
        }
      })
      .catch(error => { console.error(error); });
    }
  }

  onFirstNameChange = (event) => {
    this.setState({FirstName: event.target.value})
  }

  onLastNameChange = (event) => {
    this.setState({LastName: event.target.value})
  }

  onEmailChange = (event) => {
    this.setState({Email: event.target.value})
  }

  onDCEChange = (event) => {
    this.setState({DCE: event.target.value})
  }

  onRoleChange = (event) => {
    this.setState({Role: event.target.value})
  }

  onSectionChange = (event) => {
    const localSections = this.state.TempExtra;
    let section = localSections.find(section => section.name === event.target.value);
    if (section !== undefined) {
      section.checked = false;
      section.disabled = true;
    }
    let curSec = localSections.find(section => section.name === this.state.Section);
    if (curSec !== undefined) {
      curSec.disabled = false;
    }
    this.setState({Section: event.target.value, TempExtra: localSections});
  }

  onStatusChange = (event) => {
    this.setState({Status: event.target.value})
  }

  onMGStandingChange = (event) => {
    this.setState({MGStanding: event.target.checked, MGStandingTime: new Date()})
  }

  onExemptChange = (event) => {
    this.setState({Exempt: event.target.checked})
  }

  onExemptDescChange = (event) => {
    this.setState({ExemptDesc: event.target.value})
  }

  onMultiCheck = () => {
    this.setState({Extra: !this.state.Extra})
  }

  onExtraSectionCheck = (sec) => {
    let localSections = this.state.TempExtra;
    let section = localSections.find(section => section.name === sec.name);
    section.checked = !sec.checked;
    this.setState({TempExtra: localSections});
  }

  handleExtra = () => {
    let adjustedList = [];
    this.state.TempExtra.forEach(section => {
      let included = false;
      this.state.TrueExtra.forEach(innerSection => {
        if (innerSection.name === section.name) {
          included = true;
        }
      });
      if (!included && section.checked) {
        adjustedList.push(section);
      } else if (included && !section.checked) {
        adjustedList.push(section);
      }
    });
    return adjustedList;
  }

  errorCheck = () => {
    if (blankCheck(this.state.FirstName)) {
      console.error("Blank field: First Name")
      alert("Blank field: First Name")
      return false;
    }
    if (nameCheck(this.state.FirstName)) {
      console.error("Invalid First Name: " + this.state.FirstName);
      alert("Invalid First Name: " + this.state.FirstName + "\nPlease fix input");
      return false;
    }
    if (blankCheck(this.state.LastName)) {
      console.error("Blank field: Last Name")
      alert("Blank field: Last Name")
      return false;
    }
    if (nameCheck(this.state.LastName)) {
      console.error("Invalid Last Name: " + this.state.LastName);
      alert("Invalid Last Name: " + this.state.LastName + "\nPlease fix input");
      return false;
    }
    if (blankCheck(this.state.DCE)) {
      console.error("Blank field: DCE")
      alert("Blank field: DCE")
      return false;
    }
    if (dceCheck(this.state.DCE)) {
      console.error("Invalid DCE: " + this.state.DCE);
      alert("Invalid DCE: " + this.state.DCE + "\nPlease fix input");
      return false;
    }
    if (blankCheck(this.state.Email)) {
      console.error("Blank field: Email")
      alert("Blank field: Email")
      return false;
    }
    if (emailCheck(this.state.Email)) {
      console.error("Invalid Email: " + this.state.Email);
      alert("Invalid Email: " + this.state.Email + "\nPlease fix input");
      return false;
    }
    if (this.state.Email.includes("@g.rit.edu")) {
      this.setState({Email: this.state.DCE+"@rit.edu"});
    }
    return true;
  }

  extraSectionRender = () => {
    return (
      <div className='extraSec'>
        {this.state.TempExtra.map(section =>
          <label 
            className='secretaryLabel sectionCheck align-center'
            style={{color: section.disabled ? 'grey' : 'black'}} 
            key={section.name}
            disabled={section.disabled}
          >
            <input 
              type='checkbox'
              className='sectionCheck'
              onChange={() => this.onExtraSectionCheck(section)}
              checked={section.checked}
              disabled={section.disabled}
            />
            {section.name}
          </label>
        )}
      </div>
    )
  }

  submitData = () => {
    if(this.errorCheck()) {
      this.setState({Error: false})
      let newUser = {
        FirstName: this.state.FirstName,
        LastName: this.state.LastName,
        Email: this.state.Email,
        DCE: this.state.DCE,
        Role: this.state.Role,
        Section: this.state.Section,
        Status: this.state.Status,
        MGStanding: this.state.MGStanding ? 1 : 0,
        Exempt: this.state.Exempt? 1 : 0,
        ExemptDesc: this.state.ExemptDesc,
        MGStandingTime: this.state.MGStandingTime,
        ExtraSections: this.handleExtra(),
      }
      var url = this.state.constants.hostname + '/api/secretary/register';
      if (this.state.Edit) {
        fetch(url,
          {
            body: JSON.stringify({ user: newUser, queryDCE: this.state.queryDCE }),
            headers: {
              'Authorization':`${localStorage.getItem(USERID)}`,
              'Content-Type': 'application/json',
            },
            method: 'PUT',
          })
        .then(res => {
          if (!res.ok) {
            this.setState({Error: true})
            return false;
          }
          else {
            this.setState({Submit: true})
          }
        })
        .catch(error => { console.error(error); })
      } else {
        fetch(url,
          {
            body: JSON.stringify({ user: newUser, queryDCE: this.state.queryDCE }),
            headers: {
              'Authorization':`${localStorage.getItem(USERID)}`,
              'Content-Type': 'application/json',
            },
            method: 'POST',
          })
        .then(res => {
          if (!res.ok) {
            this.setState({Error: true})
            return false;
          }
          else {
            this.setState({Submit: true})
          }
        })
        .catch(error => { console.error(error); })
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.submitData()
  }

  render() {
    return (
      <div>
        {this.state.Submit?
          <Redirect to={`/secretary/user/${this.state.DCE}`} />
          :
          null
        }
        {this.state.Error?
          <h2>An Error Occurred: User Already Exists</h2>
          :
          null
        }
        <h3>Create/Edit User</h3>
        <div className='secBarNav'>
          <Link
            to='/secretary/users'
            className='secBarLink'
          >
            <p className='secBarText'>To Users List (will NOT save)</p>
          </Link>
          <Link
            to='/secretary/statusAudit'
            className='secBarLink'
          >
            <p
              className='secBarText'
              style={{marginLeft: "10px"}}
            >
              To Audit Screen (will NOT save)
            </p>
          </Link>
        </div>
        <hr />
        <form className='userForm'>
          <label className='secretaryLabel'>
            First Name:
            <input
              type="text"
              name="FirstName"
              onChange={this.onFirstNameChange}
              value={this.state.FirstName}
              className="secretaryInput"
              maxLength="50"
            />
          </label>
          <label className='secretaryLabel'>
            Last Name:
            <input
              type="text"
              name="LastName"
              onChange={this.onLastNameChange}
              value={this.state.LastName}
              className="secretaryInput"
              maxLength="50"
            />
          </label>
          <label className='secretaryLabel'>
            Email:
            <input
              type="text"
              name="Email"
              onChange={this.onEmailChange}
              value={this.state.Email}
              className="secretaryInput"
              maxLength="17"
            />
          </label>
          <label className='secretaryLabel'>
            DCE:
            <input
              type="text"
              name="DCE"
              onChange={this.onDCEChange}
              value={this.state.DCE}
              className="secretaryInput"
              maxLength="7"
            />
          </label>
          <label className='secretaryLabel'>
            Role:
            <select
              value={this.state.Role}
              onChange={this.onRoleChange}
              className="secretaryInput"
            >
              <option default value="M">Member</option>
              <option value="A">Admin</option>
              <option value="P">President</option>
              <option value="V">Vice President</option>
              <option value="T">Treasurer</option>
              <option value="C">Conductor</option>
              <option value="S">Secretary</option>
              <option value="L">Section Leader</option>
            </select>
          </label>
          <label className='secretaryLabel'>
            Section:
            <select
              value={this.state.Section}
              onChange={this.onSectionChange}
              className="secretaryInput"
            >
              <option default value="---">---</option>
              <option value="Flutes">Flutes</option>
              <option value="Clarinets">Clarinets</option>
              <option value="Saxophones">Saxophones</option>
              <option value="Trumpets">Trumpets</option>
              <option value="Mellophones">Mellophones</option>
              <option value="TenorSaxophones">Tenor Saxophones</option>
              <option value="Trombones">Trombones</option>
              <option value="Tubas">Tubas</option>
              <option value="Percussion">Percussion</option>
            </select>
          </label>
          <label className='secretaryLabel align-center'>
            Extra Sections:
            <input 
              type='checkbox'
              onChange={this.onMultiCheck}
              checked={this.state.Extra}
            />
          </label>
          <div style={{display: this.state.Extra ? "" : "none"}}>
            {this.extraSectionRender()}
          </div>
          <label className='secretaryLabel'>
            Status:
            <select
              value={this.state.Status}
              onChange={this.onStatusChange}
              className="secretaryInput"
            >
              <option default value="---">---</option>
              <option value="F">First Year</option>
              <option value="M">Returning Member</option>
              <option value="P">Alumni</option>
              <option value="A">Active Alumni</option>
            </select>
          </label>
          <label className='secretaryLabel'>
            Good standing (select for true for previous semester):
            <input
             type="checkbox"
             className="secretaryInput"
             onChange={this.onMGStandingChange}
             checked={this.state.MGStanding}
           />
          </label>
          <label className='secretaryLabel'>
            Exempt (select for true):
            <input
             type="checkbox"
             className="secretaryInput"
             onChange={this.onExemptChange}
             checked={this.state.Exempt}
           />
          </label>
          <label className='secretaryLabel'>
            Exemption Description:
            <input
              type="text"
              name="ExemptionDesc"
              onChange={this.onExemptDescChange}
              value={this.state.ExemptDesc}
              className="secretaryInput"
              maxLength="50"
            />
          </label>
          <button
            onClick={this.handleSubmit}
            className='secretarySubmit'
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(UserEdit)
