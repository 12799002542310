import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { blankCheck, dceCheck } from '../../Errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App.js';

class CSVEvent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
      orderedInput: [],
      parsing: false,
      finished: false,
      Submit: false,
      eventID: 0,
      events: [],
      noEvent: false,
    }
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/secretary/events?user=' + this.state.user.uid;
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then(data => {
        if (!data) { return; }
        this.setState({ events : data });
    })
    .catch(error => { console.error(error); });
  }

  sendData = (newUser, idx, len) => {
    var url = this.state.constants.hostname + '/api/secretary/addAttendant';
    return new Promise((resolve, reject) => {
      fetch(url, {
        body: JSON.stringify({ userInfo: newUser.DCE, eventID: this.state.eventID }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${localStorage.getItem(USERID)}`
        },
        method: 'POST',
      })
      .then(res => {
        if (!res.ok) {
          reject("Entry already exists")
        } else {
          resolve();
        }
      })
    })
  }

  resolveData = (errorInput) => {
    if (errorInput.length !== 0) {
      this.setState({orderedInput: errorInput, Error: true, noEvent: false})
    } else {
      this.setState({Submit: true, noEvent: false})
    }
  }

  submitUpload = async () => {
    let errorInput = [];
    let counter = 0;
    let elementsToAdd = this.state.orderedInput.length
    for (let i = 0; i < this.state.orderedInput.length; i++) {
      let newUser = this.state.orderedInput[i];
      if (!this.errorCheck(newUser)) {
        errorInput[counter] = newUser;
        counter++;
      } else {
        await this.sendData(newUser, i)
        .then(result => {
          if (i+1===elementsToAdd) {
            this.resolveData(errorInput);
          }
        })
        // eslint-disable-next-line
        .catch(error => {
          errorInput[counter] = newUser;
          counter++;
          if (i+1===elementsToAdd) {
            this.resolveData(errorInput);
          }
        })
      }
    }
  }

  elementChange = (arrayIndex, event) => {
    var fixedInput = this.state.orderedInput;
    fixedInput[arrayIndex].DCE = event.target.value
    this.setState({orderedInput: fixedInput})
  }

  errorCheck = (newUser) => {
    if (!blankCheck(newUser.DCE)) {
      if (dceCheck(newUser.DCE)) {
        newUser.DCEError = "Invalid Entry"
        return false;
      }
    } else {
      newUser.DCEError = "Blank Entry"
      return false;
    }
    if (this.state.eventID === 0) {
      if (!this.state.noEvent) {
        alert("No event selected. Please select an event")
        this.setState({noEvent: true})
      }
      return false;
    }
    return true;
  }

  confirm = (index, user) => {
    if (window.confirm("Delete this user from the event attendance list?")) {
      var tempArray = this.state.orderedInput;
      for (let i = 0; i < this.state.orderedInput.length; i++) {
        let tempUser = this.state.orderedInput[i];
        if (tempUser.DCE === user.DCE) {
          tempArray.splice(i,1)
          this.setState({orderedInput: tempArray})
          break;
        }
      }
    }
  }

  onEventChange = (event) => {
    this.setState({eventID: event.target.value})
  }

  readFile = (event) => {
    this.setState({parsing: true});
    let file = event.target.files[0];
    let reader = new FileReader();
    let counter = 0;
    reader.onload = (e) => {
      let text = reader.result;
      let rows = text.split('\n');
      let first = true;
      let order = 0;
      let reorderedElements = [];
      rows.forEach((element, index) => {
        let rowItems = element.split(",");
        if (first) {
          //Finding the column that contains the dce's for the users
          //(Allows for CSV with columns of unneeded elements)
          for (let i = 0; i < rowItems.length; i++) {
            rowItems[i] = rowItems[i].replace(/["']+/g, '').replace(/\s/g, '').trim()
            if (rowItems[i].toLowerCase() === "dce") {
              order = i;
              break;
            }
          }
          first = false;
        }
        else if (rowItems[0] !== "") {
          rowItems[order] = rowItems[order].replace(/["']+/g, '').replace(/\s/g, '').trim()
          const newUser = {
            DCE: rowItems[order],
            DCEError: "",
          }
          this.errorCheck(newUser)
          reorderedElements[counter] = newUser;
          counter++;
        }
      });
      this.setState({orderedInput: reorderedElements, parsing: false, finished: true, noEvent: false})
    }
    reader.readAsText(file);
  }

  render() {
    return (
      <div>
        {this.state.Submit?
          <Redirect to={'/secretary/events'} />
          :
          null
        }
        {this.state.Error?
          <h2>The following elements could not be added. Please check to ensure they aren't already apart of the event</h2>
          :
          null
        }
        <h3>File Upload</h3>
        <div className='secBarNav'>
          <Link
            to='/secretary/home'
            className='secBarLink'
          >
            <p className='secBarText'>Back to Secretary Home</p>
            </Link>
          <Link
            to='/secretary/events'
            className='secBarLink'
          >
            <p className='secBarText' style={{marginLeft: "10px"}}>Go to Events</p>
          </Link>
        </div>
        <hr />
        <form>
          <label className='secretaryLabel'>
            Event:
            <select
              value={this.state.eventID}
              onChange={this.onEventChange}
              className="secretaryInput"
            >
              <option default value={0}>---</option>
              {
                this.state.events.map((event) =>
                  <option key={event.Eid} value={event.Eid}>{event.Name}</option>
                )
              }
            </select>
          </label>
        </form>
        <div>
          <div className='oneline'>
            <p className="fileInputTag">Select a CSV file to upload:
              <input type="file" className="fileInput" onChange={this.readFile} />
            </p>
            {this.state.parsing?
              <div className="loader"></div>
              :
              null
            }
          </div>
          {this.state.finished?
            <div>
              <div className='oneline'>
                <p className='fileInfo'>Elements have finished loading!</p>
                <p className='fileInfo' style={{marginLeft: '3px'}}>Please review the following entries and make any changes.</p>
              </div>
              <p className='fileInfo'>Number of users read: {this.state.orderedInput.length}</p>
              <div className='oneline'>
                <p className='fileInfo'>When done, please hit the submit button.</p>
                <button className='fileSubmit' onClick={this.submitUpload}>Submit</button>
              </div>
            </div>
            :
            null
          }
          </div>
          <div className='tableDiv'>
            <table className='userTable'>
              <thead>
                <tr className='userRow userHeaderRow'>
                  <th className='userHeader'>DCE</th>
                  <th className='userHeader'>Delete</th>
                </tr>
              </thead>
              <tbody>
                {this.state.orderedInput.map((user, index) =>
                  <tr key={index} className='userRow'>
                    <td className='userEntry'>
                      {this.state.orderedInput[index].DCEError===""?
                      <input
                        type='text'
                        onChange={(e) => this.elementChange(index, e)}
                        value={this.state.orderedInput[index].DCE}
                      />
                      :
                      <div className='tooltip'>
                        <input
                          type='text'
                          style={{background: "yellow"}}
                          onChange={(e) => this.elementChange(index, e)}
                          value={this.state.orderedInput[index].DCE}
                        />
                        <span className='tooltiptext'>
                          {this.state.orderedInput[index].DCEError}
                        </span>
                      </div>
                      }
                    </td>
                    <td className='userEntry'>
                      <FontAwesomeIcon
                        icon="trash-alt"
                        className='faDelete'
                        onClick={() => this.confirm(index, user)}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
      </div>
    );
  }
}

export default withRouter(CSVEvent);
