import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Home } from "./VotingPages/Home";
import { CreateElection } from "./VotingPages/CreateElection";
import { Results } from "./VotingPages/Results"
import { authRole } from "../..";
import { Election } from "./VotingPages/Election";
import { USERID } from "../App";

export class Vote extends Component{
    constructor(props) {
        super(props)
        this.state = {
          ...this.props.state,
          goodStanding: 0
        }
    }

    componentDidMount() {
        var url = this.state.constants.hostname + '/api/goodStanding';
        fetch(url, {
          headers: {'Authorization':`${localStorage.getItem(USERID)}`}
        })
        .then(res => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then(data => {
          if (!data) { return; }
          this.setState({ goodStanding : data[0].GStanding});
        })
        .catch(error => { console.error(error); });
      }

    render() {
        return (
            <div id="Vote">
                {this.state.goodStanding?
                    <Switch>
                        <Route path='/vote/home' component={() => <Home state={this.state} />}/>
                        <Redirect exact from='/vote' to='/vote/home'/>
			                  <Route path='/vote/election/:sElection' component={() => <Election state={this.state} />}/>
                        {authRole(this.props.state.userRole, this.state.constants.roles.president)?
                            <Route path='/vote/create' component={() => <CreateElection state={this.state} />} />
                            :
                            <Redirect to='/404' />
                        }
                        {authRole(this.props.state.userRole, this.state.constants.roles.president)?
                            <Route path='/vote/results' component={() => <Results state={this.state} />} />
                            :
                            <Redirect to='/404' />
                        }
                        <Redirect to='/404' />
                    </Switch>
                :
                <div>
                    <p>You do not have good standing, and cannot vote</p>
                    <p>If you have good standing (as indicated by the Attendance Page), Contact the Webmaster</p>
		                <p>If you do not have good standing but think you should, Contact the Secretary</p>
                </div>
                }
            </div>
        )
    }
}
