import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../css/history.css'

export class Documents extends Component {
    render() {
        return (
            <div id="documents">
                <h2>History</h2>
                  <div>
                    <ul className='historyController'>
                      <Link to='/history/2006-2007' id="historyLink"><li>2006-2007</li></Link>
                      <Link to='/history/2007-2008' id="historyLink"><li>2007-2008</li></Link>
                      <Link to='/history/2008-2009' id="historyLink"><li>2008-2009</li></Link>
                      <Link to='/history/2009-2010' id="historyLink"><li>2009-2010</li></Link>
                      <Link to='/history/2010-2011' id="historyLink"><li>2010-2011</li></Link>
                      <Link to='/history/2011-2012' id="historyLink"><li>2011-2012</li></Link>
                      <Link to='/history/2012-2013' id="historyLink"><li>2012-2013</li></Link>
                      <Link to='/history/2013-2014' id="historyLink"><li>2013-2014</li></Link>
                      <Link to='/history/2014-2015' id="historyLink"><li>2014-2015</li></Link>
                      <Link to='/history/2015-2016' id="historyLink"><li>2015-2016</li></Link>
                    </ul>
                  </div>
                  <div>
                    <ul className='historyController'>
                      <Link to='/history/2016-2017' id="historyLink"><li>2016-2017</li></Link>
                      <Link to='/history/2017-2018' id="historyLink"><li>2017-2018</li></Link>
                      <Link to='/history/2018-2019' id="historyLink"><li>2018-2019</li></Link>
                      <Link to='/history/2019-2020' id="historyLink"><li>2019-2020</li></Link>
                      <Link to='/history/2020-2021' id="historyLink"><li >2020-2021</li></Link>
                      <Link to='/history/2021-2022' id="historyLink"><li >2021-2022</li></Link>
                      <Link to='/history/2022-2023' id="historyLink"><li >2022-2023</li></Link>
                      <Link to='/history/2023-2024' id="historyLink"><li >2023-2024</li></Link>
                    </ul>
                  </div>
                <h2>Documents</h2>
                <iframe
                  title="documents"
                  className="documents-frame-docs"
                  src="https://drive.google.com/embeddedfolderview?id=0Bz__clFh4GznM3RYdUxLcnVmSEk&resourcekey=0-sFI8ESS_6bFFjwWALgF9yQ&usp=sharing"
                >
                </iframe>
                <h2>EBoard Minutes</h2>
                <iframe title="minutes" className="documents-frame-minutes" src="https://drive.google.com/embeddedfolderview?id=1MmH_OIYCAVq9o4Eoy7FgkZZj2KBg4qMg&resourcekey=0-sFI8ESS_6bFFjwWALgF9yQ&usp=sharing"></iframe>
            </div>
        );
    }
}
