import React, { Component } from 'react';

export class History0708 extends Component {
    render() {
        return (
          <div className="History">
              <h2>History 2007-2008</h2>
              <h2>E-Board:</h2>
                <p><b>President: </b>Stephanie Dorn</p>
                <p><b>Vice President: </b>Mark Negro</p>
                <p><b>Secretary: </b>Amanda Preske </p>
                <p><b>Treasurer: </b>Spencer Herendeen</p>
                <p><b>Conductor: </b>Brian Abraham (Abe)</p>
                <p>Conductor Attire: Full Tuxedo with cumber bun, tiger print bow tie, white gloves, and white baton</p>

              <h3>Graduating Members</h3>
                <p>Brian Abraham (Saxophone/Keyboard)</p>
                <p>Mark Davis</p>
                <p>Stephanie Dorn</p>
                <p>Tim George (Saxophone)</p>

              <h3>New Music</h3>
                <p>Bang On The Drum</p>
                <p>Come Sail Away</p>
                <p>Crazy Train</p>
                <p>Final Countdown</p>
                <p>Imperial March</p>
                <p>Jaws</p>
                <p>Lets Go Band</p>
                <p>Night on Bald Mountain</p>
                <p>Rick Roll Song</p>
                <p>Smoke On the Water</p>
                <p>Sweet Caroline</p>
                <p>Yeah</p>

              <h3>Accomplishments and Achievements</h3>
                <p>Played at Blue Cross Arena</p>
                <p>Played for Alumni Dinner at Blue Cross Arena</p>
                <p>Played at Imagine RIT</p>
                <p>Purchasing Two Sousaphones</p>
                <p>Purchasing Drums and Drum equipment</p>
                <p>Had a Conductor in from of the Band</p>
                <p>Recruited freshman at Orientation Club Fair</p>
                <p>Guitar Hero Tournament Fundraiser</p>
                <p><b>Max Attendance at an Event: 35</b></p>
            </div>
        );
    }
}
