import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App.js';

export class Events extends Component {
  constructor(props) {
    super(props)
    this.state = {...this.props.state, events: [], filteredEvents: [], eventPage: ""};
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/secretary/events';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then(data => {
        if (!data) { return; }
        this.setState({ events : data, filteredEvents: data });
    })
    .catch(error => { console.error(error); });
  }

  handleDownload() {
    let csvContent = "data:text/csv;charset=utf-8,"
      + this.state.events.map(e => `${e.Name},${e.DAY},${e.Type},${e.Semester}`).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    let now = new Date();
    link.setAttribute("download", `Events-(${now.getMonth()+1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }

  rowClick(EID) {
    this.setState({eventPage: EID});
  }

  render() {
    return (
      <div>
        {this.state.eventPage !== "" ?
          <Redirect to={`/secretary/event/${this.state.eventPage}`} />
          :
          null
        }
        <div className='secBarNav'>
          <Link
            to='/secretary/home'
            className='secBarLink'
          >
            <p className='secBarText'>Back to Secretary Home</p>
          </Link>
          <Link
            to='/secretary/eventEdit'
            className='secBarLink'
            state={this.state}
          >
            <div className='oneline'>
              <FontAwesomeIcon icon="plus-circle" className='faAdd'/>
              <p className='secBarText'>
                Add Event
              </p>
            </div>
          </Link>
          <FontAwesomeIcon
            icon="file-download"
            className='faEdit'
            onClick={() => this.handleDownload()}
          />
        </div>
        <hr />
        <h3>Events</h3>
        <div className='tableDiv'>
          <table className='userTable'>
            <thead>
              <tr className='userRow userHeaderRow'>
                <th className='userHeader'>Name</th>
                <th className='userHeader'>Date</th>
                <th className='userHeader'>Type</th>
                <th className='userHeader'>Semester</th>
                <th className='userHeader'>Counts as a Rehearsal?</th>
              </tr>
            </thead>
            <tbody>
              {this.state.filteredEvents.map(event =>
                <tr key={event.Eid} className='userRow' onClick={() => this.rowClick(event.Eid)}>
                  <td className='userEntrySeparate'>{event.Name}</td>
                  <td className='userEntrySeparate'>{event.DAY}</td>
                  <td className='userEntrySeparate'>{event.Type}</td>
                  <td className='userEntrySeparate'>{event.Semester}</td>
                  <td className='userEntrySeparate'>{event.Rehearsal==='Y'?"Yes":"No"}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
