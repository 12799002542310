import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { USERID } from '../App.js'

export class MusicList extends Component {
  constructor(props) {
    super(props)
    this.state = {...this.props.state, songs:[], filteredSongs: [] };
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/music';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then(data => {
      if (!data) { return; }
        this.setState({ songs : data, filteredSongs: data});
    })
    .catch(error => { console.error(error); });
  }

  handleSearch = (event) => {
    var filteredSongs = [];
    var idx = 0;
    var searchVal = event.target.value.toLowerCase();
    this.state.songs.filter(song => {
      if (song.Title.toLowerCase().includes(searchVal)) {
        filteredSongs[idx] = song;
        idx++;
      }
    return filteredSongs;
    })
    this.setState({filteredSongs: filteredSongs});
  }

  render() {
    return (
      <div id="Music List">
        <h3>Music</h3>
        <input
          onChange={this.handleSearch}
          className='songSearch'
          placeholder="Search for songs..."
        />
        <table>
          <tbody>
          {this.state.filteredSongs.map(song =>
            <tr key={song.Slug}>
              <td>
                <div>
                  <Link className='clickable' to={"/music/" + song.Slug}>{song.Title}</Link>
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    );
  }
}
