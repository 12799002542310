import React, { Component } from 'react';
import '../../../css/history.css'

export class History1920 extends Component {
    render() {
        return (
          <div className="History">
              <h2>History 2019-2020</h2>
              <h2>E-Board:</h2>
                <p><b>President: </b>Peyton Schmidt</p>
                <p><b>Fall Social Vice President: </b>Daniel Gude</p>
                <p><b>Spring Social Vice President: </b>Surya Srinivasan</p>
                <p><b>Vice President: </b>Rachel Milcic</p>
                <p><b>Secretary: </b>Emma Lubes</p>
                <p><b>Fall Treasurer: </b>Nathan Fritz</p>
                <p><b>Spring Treasurer: </b>Derek Fought</p>
                <p><b>Conductor: </b>David Patch</p>
                <p><i>Conductor Attire: </i>?</p>

              <h3>Accomplishments and Achievements</h3>
                <p>First year with dual-ballot eboard members</p>
                <p><b>Max Attendance at an Event: </b></p>
            </div>
        );
    }
}
