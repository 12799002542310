import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { authRole } from "../../..";
import { USERID } from "../../App";

export class Home extends Component{
    constructor(props) {
      super(props)
      this.state = {
          ...this.props.state,
          availableElections: [],
          sElection: ""
      }
    }

    componentDidMount() {
      var url = this.state.constants.hostname + '/api/voting/availableElections';
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        return res.json();
      })
      .then(data => {
        if (!data) { return; }
        this.setState({ availableElections : data});
      })
      .catch(error => { console.error(error); });
    }

    handleRowClick = (election) => {
      this.setState({sElection: election.ElectionName})
    }

    convertElectionTR(election) {
      return(
        <tr onClick={() => this.handleRowClick(election)}>
            <td style={{'text-decoration': 'underline', 'color': 'blue'}}>{election.ElectionName}</td>
            <td>{election.EndDate}</td>
        </tr>
      )
    }

    render() {
        const roles = this.props.state.constants.roles;
        return (
            <div>
                {this.state.sElection !== "" ?
                  <Redirect to={`/vote/election/${this.state.sElection}`} />
                  :
                  null
                }
                {authRole(this.props.state.userRole, roles.president) ?
                <div>
                  <Link
                    to='/vote/create'
                    className='secBarLink'
                    state={this.state}
                  >
                    <div className='oneline'>
                      <p className='secBarText'>
                        Create Election
                      </p>
                    </div>
                    <br/>
                  </Link>
                  <Link
                    to='/vote/results'
                    className='secBarLink'
                    state={this.state}
                  >
                    <div className='oneline'>
                      <p className='secBarText'>
                        View Election Results
                      </p>
                    </div>
                    <br/>
                  </Link>
                </div>
                :
                null
              }
              <br/>
              <h2>Elections: </h2>
              <table>
                <tr>
                  <th>Title</th>
                  <th>End Date</th>
                </tr>
                {this.state.availableElections.map(election => this.convertElectionTR(election))}
              </table>
            </div>
        )
    }
}