import React, { Component } from 'react';
import '../../../css/history.css'

export class History2324 extends Component {
    render() {
        return (
            <div className="History">
                <h2>History 2023-2024</h2>
                <h2>E-Board:</h2>
                <p><b>President: </b>Xander Tooze</p>
                <p><b>Vice President: </b>Liam Almekinder</p>
                <p><b>Secretary: </b>Brian Derryberry</p>
                <p><b>Treasurer: </b>Dan Rigoglioso</p>
                <p><b>Conductor: </b>Collin Fluke</p>
                <p><i>Conductor Attire: </i>Concert black with an orange suit vest and a black and orange paisley tie</p>

                <h3>Accomplishments and Achievements</h3>
                <p>Back to Rehearsing in GPC</p>
                <p>Created and Used the PB&J System (Pep Band ANonymous Direct Judicial Appointment Yearly)</p>
                <p>Played in front of 3 sold out games at GPC</p>
                <p>Made it to finals for the first time since 2016</p>
                <p>Every Single Day of the Spring Semester, an Election was happening</p>
                <p>Most Constitutional Amendments in a year (6)</p>
                <p>Constitutional Amendments: </p>
                <p>&emsp; - If a position becomes available in the spring, Eboard can hold an election that will elect the position for the next three academic semesters (including the current one)</p>
                <p>&emsp; - Moved Pitched Percussion to the Flute Section and Basses/Guitars to Sousa/Bari</p>
                <p>&emsp; - Limited Large Section Leader Elections to just the specific section</p>
                <p>&emsp; - Clarified what is defined as a member versus alumnus</p>
                <p>&emsp; - Limited how many Eboard votes one member can have</p>
                <p>Won the dance off against Penn State Hockey Pep Band</p>
                <p><b>Max Attendance at an Event: 147</b></p>
            </div>
        );
    }
}
