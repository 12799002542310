import React, { Component } from 'react';
import { USERID } from '../App.js';
import "../../css/eboard.css";

export class EBoard extends Component {
  constructor(props) {
    super(props)
    this.state={...this.props.state, eboard:[], sectionLeaders:[]};
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/eboard';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then(data => {
      if (!data) { return; }
      this.setState({ eboard : data });
    })
    .catch(error => { console.error("error"); });

    url = this.state.constants.hostname + '/api/sectionLeaders';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then(data => {
      if (!data) {
        return;
      }
      this.setState({ sectionLeaders: data });
    })
    .catch(error => {
      console.error('error');
    });
  }

  render() {
    return (
      <div id="Eboard">
        <table className="eboard">
          <thead>
            <tr className="eboard">
              <th className='eboard'></th>
              <th className="eboard">Position</th>
              <th className="eboard">Name</th>
              <th className="eboard">Cell</th>
              <th className="eboard">Email</th>
            </tr>
          </thead>
          <tbody>
          {this.state.eboard.map(person =>
            <tr key={person.Email}>
              <td>
                <img
                  src={"/media/eboard_pics/" + person.Slug + ".jpg"}
                  alt={person.Position}
                  className='eboardImage'
                >
                </img>
              </td>
              <td className="eboard">{person.Position}</td>
              <td className="eboard">{person.FirstName} {person.LastName}</td>
              <td className="eboard"><a href={"tel:" + person.Cell}>{person.Cell}</a></td>
              <td className="eboard"><a href={"mailto:" + person.Email}>{person.Email}</a></td>
            </tr>
          )}
          </tbody>
        </table>
	<br/>
	<h3>Section Leaders</h3>
	<table className="eboard">
          <thead>
            <tr className="eboard">
              <th className='eboard'></th>
              <th className="eboard">Section</th>
              <th className="eboard">Name</th>
              <th className="eboard">Cell</th>
              <th className="eboard">Email</th>
            </tr>
          </thead>
          <tbody>
          {this.state.sectionLeaders.map(person =>
            <tr key={person.Email}>
              <td>
                <img
                  src={'/media/eboard_pics/' + person.Section + '.jpg'}
                  alt={person.Section}
                  className='eboardImage'
                >
                </img>
              </td>
              <td className="eboard">{person.Section}</td>
              <td className="eboard">{person.FirstName} {person.LastName}</td>
              <td className="eboard"><a href={'tel:' + person.Cell}>{person.Cell}</a></td>
              <td className="eboard"><a href={'mailto:' + person.Email}>{person.Email}</a></td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    );
  }
}
