import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authRole } from "../../../index.js";
import ReactMarkdown from 'react-markdown';
import "../../../css/qdb.css";
import { USERID } from '../../App.js';

export class Home extends Component {
  constructor(props) {
    super(props)
    this.state={...this.props.state, quotes:[], filteredQuotes: []};
    this.confirm = this.confirm.bind(this)
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/qdb/public';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then(data => {
      if (!data) { return; }
      this.setState({ quotes : data, filteredQuotes: data });
    })
    .catch(error => { console.error("error"); });
  }

  handleSearch = (event) => {
    var filteredQuotes = [];
    var searchVal = event.target.value.toLowerCase();
    this.state.quotes.filter(quote => {
      if (quote.Title.toLowerCase().includes(searchVal)
        || quote.Person.toLowerCase().includes(searchVal)
        || quote.Quote.toLowerCase().includes(searchVal)
      ) {
        filteredQuotes.push(quote);
      }
      return filteredQuotes;
    })
    this.setState({filteredQuotes: filteredQuotes});
  }

  confirm(qid) {
    if (window.confirm("Delete this quote?")) {
      var url = this.state.constants.hostname + '/api/qdb/removeQuote';
      fetch(url, {
        body: JSON.stringify({QID: qid}),
        headers: {
          'Authorization':`${localStorage.getItem(USERID)}`,
          'Content-Type':'application/json',
        },
        method: 'DELETE',
      })
      .then(res => {
        this.setState({delete: true})
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .then(completed => {
        let tempQuotes = [];
        this.state.quotes.forEach(quote => {
          if (quote.QID !== qid) {
            tempQuotes.push(quote);
          }
        });
        this.setState({quotes: tempQuotes, filteredQuotes: tempQuotes})
      })
      .catch(error => { console.error(error); });
    }
  }

  render() {
    const roles = this.props.state.constants.roles;
      return (
        <div id="Qdb">
          <h2>QDB</h2>
            <div className='oneline'>
              <Link
                to='/qdb/addQuote'
                className='secBarLink'
                state={this.state}
              >
                <div className='oneline'>
                  <FontAwesomeIcon icon="plus-circle" className='faAdd'/>
                  <p className='secBarText'>
                    Add Quote
                  </p>
                </div>
              </Link>
              {authRole(this.props.state.userRole, roles.president) ?
                <Link
                  to='/qdb/approveQuote'
                  className='secBarLink'
                  state={this.state}
                >
                  <div className='oneline'>
                    <FontAwesomeIcon icon="check-square" className='faAdd'/>
                    <p className='secBarText'>
                      Approve Quotes
                    </p>
                  </div>
                  <br/>
                </Link>
                :
                null
              }
            </div>
          <hr/>
          <br/>
          <div className='oneline center'>
            <p className='searchTag'><b>Search:</b></p>
            <input
              onChange={this.handleSearch}
              className='quoteSearch'
              placeholder="Search for quotes..."
            />
          </div>
          <div className="qdbTables">
            {this.state.filteredQuotes.map(quote =>
              <div id={quote.QID} key={quote.QID}>
                <table className="QDBTable">
                  <tbody>
                    <tr className="QDBTitle">
                      <th colSpan='2'>{quote.Title}</th>
                    </tr>
                    <tr className="QDBRow quoteRow">
                      <td colSpan='2' className='quote multiline'>
                        <ReactMarkdown source={quote.Quote} />
                      </td>
                    </tr>
                    <tr className="QDBRow secondary">
                      <td className='quotePerson'>-{quote.Person}</td>
                      <td>
                        {authRole(this.props.state.userRole, roles.admin) ?
                          <FontAwesomeIcon
                            icon="trash-alt"
                            className='faDelete qdbDelete'
                            onClick={() => this.confirm(quote.QID)}
                          />
                          :
                          null
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      );
    }
}
