import React, { Component } from 'react';
import '../../../css/history.css'

export class History2223 extends Component {
    render() {
        return (
            <div className="History">
                <h2>History 2022-2023</h2>
                <h2>E-Board:</h2>
                <p><b>President: </b>Travis Cain</p>
                <p><b>Vice President: </b>Destini Schmit</p>
                <p><b>Secretary: </b>Liam Almekinder</p>
                <p><b>Fall Treasurer: </b>Will Hostetter</p>
                <p><b>Spring Treasurer: </b>Nihar Patel</p>
                <p><b>Conductor: </b>Trey Pachucki</p>
                <p><i>Conductor Attire: </i>Steampunk Hat and Grey suit</p>

                <h3>Accomplishments and Achievements</h3>
                <p>Record Breaking Puck Raffle Profit</p>
                <p>Played At Rochester Special Hockey</p>
                <p>Bought more Christmas Music</p>
                <p>Constitutional Amendments: Standardized Section Leader Elections</p>
                <p><b>Max Attendance at an Event: 140</b></p>
            </div>
        );
    }
}
