import React, { Component } from 'react';
import { USERID } from '../App.js';

export class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.state;
    }

    componentDidMount() {
        fetch(this.state.constants.hostname + '/api/revision', {
          headers: {'Authorization':`${localStorage.getItem(USERID)}`}
        })
        .then(res => {
            if (!res.ok) {
                return false;
            }
            return res.json();
        })
        .then(data => {
            if (!data) { return; }
            this.setState(data);
        })
        .catch(error => { console.error(error); });
    }

    render() {
        return (
            <div id="admin">
                <h2>Admin</h2>
                <ul className='admin'>
                    <li>Application version from package.json: <strong>{this.state.version}</strong></li>
                    <li>Git commit hash from latest commit: <strong>{this.state.hash}</strong></li>
                </ul>
            </div>
        );
    }
}
