import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

export class AntiCss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.routes,
      accepted: false
    }
  }

  handleAccept = () => {
    this.setState({accepted: true})
  }

  initial = () => {
    return (
      <div>
        <h1>Welcome Initiate</h1>
        <p>For too long, Big Web has expected us to conform to their rules. They impose upon us the degenerate standards of corporate sterility. </p>

        <p>The Web used to be free. </p>
        <p>It used to be rebellious.</p>

        <p>Now it is naught but a sea of identical looking websites.</p>

        <p>And whose fault is this?!</p>

        <p>Cascading Style Sheets.</p>

        <p>CSS is the problem. Websites now have to use this barbaric coding language in order to make their websites look “better” And it doesn’t even work. The language supposedly making the internet a more complex place does nothing but overcomplicate the lives of front end developers.</p>

        <p>Well I say no more. Down With CSS! Down with every website being forced to look identical!</p>

        <p>Initiate, I call upon you. Reject Big Web’s mandate. Reject the evils of CSS! Go back to inline styling as TimBL intended. We have turned our backs upon our lord, and we must repent, and return to him.</p>

        <p>Initiate, do you swear to reject CSS and all it stands for?!</p>
        <button onClick={this.handleAccept}>
          I accept
        </button>
      </div>
    )
  }

  acceptedText = () => {
    return (
        <div>
            <p>Excellent!</p>
            <p>Now go forth, and know that you are a wolf among sheep</p>
            <p>Distance yourself from those disgraceful enough to use CSS</p>
            <p>And know that Tim is proud of you for renouncing the temptations of Big Web</p>
        </div>
    )
  }

  render() {
    return (
      <div>
      {this.state.accepted?
        this.acceptedText()
        :
        this.initial()
      }
      </div>
    );
  }
}
