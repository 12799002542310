import React, { Component } from 'react';

export class History1112 extends Component {
    render() {
        return (
          <div className="History">
              <h2>History 2011-2012</h2>
              <h2>E-Board:</h2>
                <p><b>President: </b>Gabrielle Potts</p>
                <p><b>Vice President: </b>Matt Fico</p>
                <p><b>Vice President: </b>Kelly Fidler</p>
                <p><b>Secretary: </b>Kaity Moranz</p>
                <p><b>Treasurer: </b>Dave Stoffel</p>
                <p><b>Conductor: </b>Jimmy McNatt</p>
                <p><i>Conductor Attire:</i> Orange shirt, Black Vest, Black Pants, White Tie, Bare Hands, White Fedora</p>

              <h3>DREWBA Status (three Beast Statuses - named after first recipient):</h3>
                <p>Drew Zemke (Tuba) : Earthquake</p>

              <h3>GOD Status: (Two Beast Status)</h3>
                <p>Chris Bentivenga (tuba): Earthquake</p>
                <p>Chris Donnelly (clarinet): Come Sail Away and obnoxious siren</p>
                <p>Pat McVetty Mill (tuba): Earthquake</p>
                <p>Tom Ward (trumpet): Come Sail Away Solo, Longest note</p>

              <h3>Beast Status:</h3>
                <p>Dani Campbell (clarinet): Come Sail Away</p>
                <p>Peter Goldberg (trombone): loudest note</p>
                <p>Danielle Grabowski (flute): You Can Call Me Al solo</p>
                <p>Andrew Hitchner (clarinet): Come Sail Away</p>
                <p>Zach Kirsch (trumpet): Come Sail Away Solo</p>
                <p>Lori Liebman (flute): upside down playing</p>
                <p>Kaity Moranz (clarinet): Come Sail Away</p>
                <p>Corey Muench (percussion): Wipe Out Solo</p>
                <p>Emma Nelson (clarinet): Come Sail Away</p>
                <p>Mike Potter (clarinet): Come Sail Away</p>
                <p>Chris Rudkin (alto saxophone): Pep Band Knowledge, loud mouth</p>

              <h3>Graduating Members</h3>
                <p>Jon Fabian (tuba)</p>
                <p>Matt Fico (tenor saxophone)</p>
                <p>Mike Greco (tuba)</p>
                <p>Morgan Killik (clarinet)</p>
                <p>Erin Kiscelica (clarinet)</p>
                <p>Steve McDermott (trumpet)</p>
                <p>Pat McVetty Mill (tuba)</p>
                <p>Mark Negro (percussion)</p>
                <p>Mitch Quartner (trumpet)</p>
                <p>Liz Stevens (trombone)</p>
                <p>Pat Sweazy (trumpet)</p>
                <p>Brooke Tower (flute)</p>
                <p>Tom Ward (trumpet)</p>
                <p>Drew Zemke (tuba)</p>

              <h3>New Music</h3>
                <p>Back in Black</p>
                <p>Billie Jean</p>
                <p>Born to Be Wild</p>
                <p>Boys of Summer</p>
                <p>Crazy Train</p>
                <p>Enter Sandman</p>
                <p>Everybody Needs Somebody to Love</p>
                <p>Hail to the Chief</p>
                <p>Hang on Sloopy</p>
                <p>Living on a Prayer</p>
                <p>Looney Tunes Theme</p>
                <p>Poker Face</p>
                <p>Rage!</p>
                <p>Smooth Criminal</p>
                <p>Take On Me</p>
                <p>Thriller</p>

              <h3>Accomplishments and Achievements</h3>
                <p>Played at all Men's and Women's Hockey Games</p>
                <p>Played at two playoff games at the same time</p>
                <p>Played at the womens NCAA championship Game</p>
                <p>Played at Tiger Walk</p>
                <p>Played at Club Fair</p>
                <p>Played at Spring Fest</p>
                <p>Played at Imagine RIT</p>
                <p>Made our own Pink the Rink shirts</p>
                <p>Participated in Wounded Warrior Project Game</p>
                <p>Recorded the Band</p>
                <p>Bake Sale Fundraiser</p>
                <p>Can Drive Fundraiser</p>
                <p>Jersey Raffle Fundraiser</p>
                <p>Reelected 2 eboard members</p>
                <p><b>Max Attendance at an Event: 98</b></p>
            </div>
        );
    }
}
