import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { USERID } from '../../App.js';

export class StatusCheck extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
      ...props.match.params,
      exemptionUsers: [],
      goodStandingUsers: [],
      userPage: ""
    }
  }

  rowClick(DCE) {
    this.setState({userPage: DCE});
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/secretary/exemptUsers';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then(data => {
        this.setState({exemptionUsers: data})
      })
      .catch(error => { console.error(error); })
    url = this.state.constants.hostname + '/api/secretary/manualStandingUsers'
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then(data => {
        this.setState({goodStandingUsers: data})
      })
      .catch(error => { console.error(error); })
  }

  generateExemptionRow(user) {
    if (this.section===user.SECTION) {
      return (
        <tr key={user.DCE} className='userRow' onClick={() => this.rowClick(user.DCE)}>
          <td className='userEntry'></td>
          <td className='userEntry'>{user.LastName}</td>
          <td className='userEntry'>{user.FirstName}</td>
          <td className='userEntry'>{user.ExemptDesc}</td>
        </tr>
      );
    } else {
      this.section=user.SECTION;
      return (
        <tr key={user.DCE} className='userRow' onClick={() => this.rowClick(user.DCE)}>
          <td className='userEntrySeparate'>{user.SECTION}</td>
          <td className='userEntrySeparate'>{user.LastName}</td>
          <td className='userEntrySeparate'>{user.FirstName}</td>
          <td className='userEntrySeparate'>{user.ExemptDesc}</td>
        </tr>
      );
    }
  }

  generateGStandingRow(user) {
    console.log(user)
    let date = new Date(user.MGStandingTime)
    if (this.section===user.SECTION) {
      return (
        <tr key={user.DCE} className='userRow' onClick={() => this.rowClick(user.DCE)}>
          <td className='userEntry'></td>
          <td className='userEntry'>{user.LastName}</td>
          <td className='userEntry'>{user.FirstName}</td>
          <td className='userEntry'>{date.getMonth() + 1}-{date.getDate()}-{date.getFullYear()}</td>
        </tr>
      );
    } else {
      this.section=user.SECTION;
      return (
        <tr key={user.DCE} className='userRow' onClick={() => this.rowClick(user.DCE)}>
          <td className='userEntrySeparate'>{user.SECTION}</td>
          <td className='userEntrySeparate'>{user.LastName}</td>
          <td className='userEntrySeparate'>{user.FirstName}</td>
          <td className='userEntrySeparate'>{date.getMonth() + 1}-{date.getDate()}-{date.getFullYear()}</td>
        </tr>
      );
    }
  }

  render() {
    this.section = undefined;
    return (
      <div>
        {this.state.userPage !== "" ?
          <Redirect to={`/secretary/userEdit/${this.state.userPage}`} />
          :
          null
        }
        <h3>Users with Manually Set Good Standing/Special Exemptions</h3>
        <h4>Click on a User to Change The Information</h4>
        <div className='secBarNav'>
          <Link
            to='/secretary/home'
            className='secBarLink'
          >
            <p className='secBarText'>Back to Secretary Home</p>
          </Link>
        </div>
        <hr />
        <h3>Current Users with Exemptions ({this.state.exemptionUsers.length}):</h3>
        <div className='tableDiv'>
          <table className='userTable'>
            <thead>
              <tr className='userRow userHeaderRow'>
                <th className='userHeader'>Section</th>
                <th className='userHeader'>Last Name</th>
                <th className='userHeader'>First Name</th>
                <th className='userHeader'>Exemption Description</th>
              </tr>
            </thead>
            <tbody>
            {this.state.exemptionUsers.map(user =>
              this.generateExemptionRow(user)
            )}
            </tbody>
          </table>
        </div>
        <h3>
          Current Users With Manually Set Good Standing Statuses ({this.state.goodStandingUsers.length}):
        </h3>
        <div className='tableDiv'>
          <table className='userTable'>
            <thead>
              <tr className='userRow userHeaderRow'>
                <th className='userHeader'>Section</th>
                <th className='userHeader'>Last Name</th>
                <th className='userHeader'>First Name</th>
                <th className='userHeader'>Date Added</th>
              </tr>
            </thead>
            <tbody>
            {this.state.goodStandingUsers.map(user =>
              this.generateGStandingRow(user)
            )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(StatusCheck)
