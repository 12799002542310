import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { blankCheck } from '../../Errors';
import { USERID } from '../../App.js';

class AddQuote extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      Title: "",
      Date: "",
      Person: "",
      Quote: "",
      Error: false,
      Submit: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onTitleChange = (event) => {
    this.setState({Title: event.target.value})
  }

  onDateChange = (event) => {
    this.setState({Date: event.target.value})
  }

  onPersonChange = (event) => {
    this.setState({Person: event.target.value})
  }

  onQuoteChange = (event) => {
    this.setState({Quote: event.target.value})
  }

  errorCheck = () => {
    if (blankCheck(this.state.Title)) {
      console.error("Blank field: Title")
      alert("Blank field: Title")
      return false;
    }
    if (blankCheck(this.state.Person)) {
      console.error("Blank field: Person")
      alert("Blank field: Person")
      return false;
    }
    if(blankCheck(this.state.Quote)) {
      console.error("Blank field: Quote")
      alert("Blank field: Quote")
      return false;
    }
    return true;
  }

  getDate = () => {
    let date = new Date();
    let year = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
    let time = `${date.getHours()}:${date.getMinutes()}`
    return `${year}T${time}`
  }

  submitData = () => {
    if(this.errorCheck()) {
      this.setState({Error: false})
      const quote= {
        Title: this.state.Title,
        Date: this.getDate(),
        Status: "Private",
        Person: this.state.Person,
        Quote: this.state.Quote,
      }
      var url = this.state.constants.hostname + '/api/qdb/addQuote';
      fetch(url, {
        body: JSON.stringify({ quote: quote }),
        headers: {
          'Authorization':`${localStorage.getItem(USERID)}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then(res => {
        if (!res.ok) {
          this.setState({Error: true})
          return false;
        }
        else {
          this.setState({Submit: true})
        }
      })
      .catch(error => { console.error(error); })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.submitData()
  }

  render() {
    return (
      <div>
        {this.state.Submit?
          <Redirect to={'/qdb/home'} />
          :
          null
        }
        {this.state.Error?
          <h2>The following elements could not be added. Please check to ensure they aren't already a quote</h2>
          :
          null
        }
        <h2>Add Quote to QDB</h2>
        <div className='secBarNav'>
          <Link
            to='/qdb/home'
            className='secBarLink'
          >
            <p className='secBarText'>Back to QDB Home</p>
            </Link>
        </div>
        <hr />
        <form className='userForm'>
          <label
            className='secretaryLabel'
            style={{marginTop: ".5em", marginBottom: "1em"}}
          >
            Note:
            <a
              href="https://www.markdownguide.org/cheat-sheet/"
              target="_blank"
              rel="noopener noreferrer"
              className="secBarText"
              style={{marginLeft: "3px", marginRight: "3px"}}
            >
              Markdown
            </a>
            is now supported for quotes
          </label>
          <table>
            <tbody>
              <tr>
                <td>
                  <label className='secretaryLabel'>
                    Title:
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    name="Title"
                    onChange={this.onTitleChange}
                    value={this.state.Title}
                    className="qdbInput"
                    maxLength="100"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label className='secretaryLabel'>
                    Person:
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    name="Person"
                    onChange={this.onPersonChange}
                    value={this.state.Person}
                    className="qdbInput"
                    maxLength="50"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label className='secretaryLabel'>
                    Quote:
                  </label>
                </td>
                <td>
                  <textarea
                    type="text"
                    name="FirstName"
                    onChange={this.onQuoteChange}
                    value={this.state.Quote}
                    className="quoteTextArea"
                    maxLength="255"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        <button
          onClick={this.handleSubmit}
          className='secretarySubmit'
        >
          Submit Quote
        </button>
      </div>
    );
  }
}

export default withRouter(AddQuote)
