import React, { Component } from 'react';
import '../../css/president.css';
import { nameCheck, cellCheck, emailCheck } from '../Errors';
import { USERID } from '../App.js';

export class President extends Component {
    constructor(props) {
      super(props)
      this.state = {
          ...this.props.state,
          eboard: [],
          sectionLeaders: [],
          showModal: false,
          isPrimary: null,
          FirstName: "",
          LastName: "",
          Section: "",
          Cell: "",
          Email: "",
          Slug: ""
      };

      this.handleClose = this.handleClose.bind(this);
      this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        this.loadFullEboard();
    }

    loadFullEboard() {
        var url = this.state.constants.hostname + '/api/fullEboard';
        fetch(url, {
          headers: {'Authorization':`${localStorage.getItem(USERID)}`}
        })
        .then(res => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then(data => {
          if (!data) { return; }
            this.setState({ eboard: data.eboard, sectionLeaders: data.sectionLeaders });
        })
        .catch(error => { console.error(error); });
    }

    showModal(isPrimary, editingMember) {
        this.setState({
            isPrimary,
            showModal: true,
            FirstName: editingMember.FirstName,
            LastName: editingMember.LastName,
            Section: editingMember.Section,
            Cell: editingMember.Cell,
            Email: editingMember.Email,
            Slug: editingMember.Slug
        });
    }

    hideModal() {
        this.setState({ showModal: false });
    };

    handleClose(event) {
        if (event.target.className.includes("closeModal")) {
            this.hideModal();
        }
    }

    handleSave(event) {
        if(!this.errorCheck()) {
            return;
        }

        this.updateMember();
        this.handleClose({target:{className:"closeModal"}});
    }

    updateMember(){
        var editingMember = {
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            Email: this.state.Email,
            Cell: this.state.Cell,
        };
        var url = this.state.constants.hostname + '/api/';

        if (this.state.isPrimary) {
            editingMember.Slug = this.state.Slug;
            url += 'eboard/';
        }
        else {
            editingMember.Section = this.state.Section;
            url += 'sectionLeaders/';
        }

        fetch(url,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization':`${localStorage.getItem(USERID)}`
            },
            body: JSON.stringify({ editingMember })
          })
        .then(res => {
          if (!res.ok) {
            alert("Error occured when trying to update.\nIf perssists, contact the webmaster.");
            return false;
          }
          else {
            this.loadFullEboard();
            alert("Successfully updated.");
          }
        })
        .catch(error => { console.error(error); })
    }

    onFirstNameChange = (event) => {
        this.setState({ FirstName: event.target.value });
    }

    onLastNameChange = (event) => {
        this.setState({ LastName: event.target.value });
    }

    onCellChange = (event) => {
        this.setState({ Cell: event.target.value });
    }

    onEmailChange = (event) => {
        this.setState({ Email: event.target.value });
    }

    errorCheck = () => {
      if (nameCheck(this.state.FirstName)) {
        console.error("Invalid First Name: " + this.state.FirstName);
        alert("Invalid First Name: " + this.state.FirstName + "\nPlease fix input");
        return false;
      }
      if (nameCheck(this.state.LastName)) {
        console.error("Invalid Last Name: " + this.state.LastName);
        alert("Invalid Last Name: " + this.state.LastName + "\nPlease fix input");
        return false;
      }
      if (cellCheck(this.state.Cell)) {
        console.error("Invalid Cell: " + this.state.Cell);
        alert("Invalid Cell: " + this.state.Cell + "\nPlease fix input");
        return false;
      }
      if (emailCheck(this.state.Email)) {
        console.error("Invalid Email: " + this.state.Email);
        alert("Invalid Email: " + this.state.Email + "\nPlease fix input");
        return false;
      }
      if (this.state.Email.includes("@g.rit.edu")) {
          this.setState(prevState => ({
              Email: prevState.Email.split('@')[0] + "@rit.edu"
          }));
      }
      return true;
    }

    render() {
        if(!(this.state.eboard)) {return null};

        return (
            <div id="president">
                <h2>President</h2>

                <h3>Eboard</h3>
                <table className="president">
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Cell</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                      {this.state.eboard.map(element =>
                          <tr key={element.Slug} className='presidentRow' onClick={() => this.showModal(true, element)}>
                            <td>{element.Position}</td>
                            <td>{element.FirstName}</td>
                            <td>{element.LastName}</td>
                            <td>{element.Cell}</td>
                            <td>{element.Email}</td>
                          </tr>
                      )}
                  </tbody>
                </table>

                <h3>Section Leaders</h3>
                <table className="president">
                  <thead>
                    <tr>
                      <th>Section</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Cell</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                      {this.state.sectionLeaders.map(element =>
                          <tr key={element.Section} className='presidentRow' onClick={() => this.showModal(false, element)}>
                            <td>{element.Section}</td>
                            <td>{element.FirstName}</td>
                            <td>{element.LastName}</td>
                            <td>{element.Cell}</td>
                            <td>{element.Email}</td>
                          </tr>
                      )}
                  </tbody>
                </table>

                <div className={"closeModal modal " + (this.state.showModal ?  "display-block" : "display-none")} onClick={this.handleClose}>
                  <div className="modal-main" onClick={null}>
                    <div className="modal-header">
                      <span className="modalX closeModal">&times;</span>
                      <p className="modal-title">Editing {this.state.isPrimary ? this.state.Slug : this.state.Section}</p>
                    </div>
                    <div className="modal-body">
                        <label className='presidentLabel'>
                          First Name:
                          <input
                            type="text"
                            onChange={this.onFirstNameChange}
                            value={this.state.FirstName}
                            className="presidentInput"
                            maxLength="50"
                          />
                        </label>
                        <br />
                        <label className='presidentLabel'>
                          Last Name:
                          <input
                            type="text"
                            onChange={this.onLastNameChange}
                            value={this.state.LastName}
                            className="presidentInput"
                            maxLength="50"
                          />
                        </label>
                        <br />
                        <label className='presidentLabel'>
                          Cell:
                          <input
                            type="text"
                            onChange={this.onCellChange}
                            value={this.state.Cell}
                            className="presidentInput"
                            maxLength="10"
                          />
                        </label>
                        <br />
                        <label className='presidentLabel'>
                          Email:
                          <input
                            type="text"
                            onChange={this.onEmailChange}
                            value={this.state.Email}
                            className="presidentInput"
                            maxLength="15"
                          />
                        </label>
                    </div>
                    <div className="modal-footer">
                      <p className="presidentWarning">*Updates will be effective immediately*</p>
                      <button onClick={this.handleClose} className="closeModal btn-cancel modal-btn">Cancel</button>
                      <button onClick={this.handleSave} className="btn-save modal-btn">Save</button>
                    </div>
                  </div>
                </div>

            </div>
        );
    }
}
