import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

export class Ready extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.routes,
      firstClick: false,
      secondClick: false
    }
  }

  handleFirst = () => {
    this.setState({firstClick: true})
  }

  handleSecond = () => {
    this.setState({secondClick: true})
  }

  first = () => {
    return (
      <div>
        <h1>ARE YOU READY KIDS?!?!?!</h1>
        <button onClick={this.handleFirst} className='spongeButton'>
          AYE AYE CAPTAIN
        </button>
      </div>
    )

  }

  second = () => {
    return (
      <div>
        <h1>I CAN'T HEAR YOU!</h1>
        <button onClick={this.handleSecond} className='spongeButton'>
          AYE AYE CAPTAIN!!!!!!!!!!!!!!!!
        </button>
      </div>
    );
  }

  third = () => {
    return (
      <div>
        <iframe
          title='sponge to the bob'
          width="1200"
          height="700"
          src="https://www.youtube.com/embed/r9L4AseD-aA?start=8&autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </div>
    )
  }

  render() {
    return (
      <div>
      {this.state.firstClick?
        <Redirect to="/ready/hear"/>
        :
        null
      }
      {this.state.secondClick?
        <Redirect to="/ready/hear/opener"/>
        :
        null
      }
      {this.state.routes===1 ?
        this.first()
        :
        this.state.routes===2 ?
          this.second()
          :
          this.state.routes===3 ?
            this.third()
            :
            null
      }
      </div>
    );
  }
}
