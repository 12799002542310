import React, { Component } from 'react';

export class History1516 extends Component {
    render() {
        return (
          <div className="History">
              <h2>History 2015-2016</h2>
              <h2>E-Board:</h2>
                <p><b>President: </b>Brian Besmanoff</p>
                <p><b>Vice President: </b>Steve Silkey</p>
                <p><b>Vice President: </b>Jon Theismann</p>
                <p><b>Secretary: </b>Emily O’Connor</p>
                <p><b>Treasurer: </b>Elise Walsh</p>
                <p><b>Conductor: </b>Ryan Hinkley</p>
                <p><i>Conductor Attire:</i> orange shirt, black pants, black tie</p>
                <p><i>Fancy Conductor Attire:</i> orange suit jacket and pants, white shirt, black tie</p>

              <h3>Accomplishments and Achievements</h3>
                <p>Traveled to play playoff games at Mercyhurst for the Men’s Team</p>
                <p>Traveled to Albany for NCAA Regional Games</p>
                <p>Played for Tiger Den Events including softball, crew, volleyball</p>
                <p>Recorded the band</p>
                <p>Built shelves inside the GPC Closet</p>
                <p>Played Freebird to the delight of everyone</p>
                <p>Attended the inaugural Buffalo Beauts NWHL game to support alums</p>
                <p>Played at a Rochester Americans Preseason game at GPC</p>
                <p>MegaBand with RPI at a women’s hockey game</p>
                <p><b>Max Attendance at an Event: ??</b></p>
            </div>
        );
    }
}
