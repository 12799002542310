import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App.js';

export class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {...this.props.state}
  }

  handleUpdate = () => {
    if (window.confirm(`This will now update all user's statuses for good standing. \
      \nAre you sure you want to do this? \
      \nIt might take a moment`)
    ) {
        const url = this.state.constants.hostname + '/api/updateStatus';
        fetch(url, {
          headers: {'Authorization':`${localStorage.getItem(USERID)}`}
        })
        .then(res => {
          if (!res.ok) {
            return false;
          }
          return res;
        })
        .then(data => {
          alert("Users have been updated");
        })
        .catch(error => { console.error(error); })
    }
  }

  render() {

    return (
      <div className='secNav'>
        <Link
          to='/secretary/users'
          className='secNavTile'
        >
          <FontAwesomeIcon icon="users" className='faSecHome'/>
          <p className='secText'>Users</p>
        </Link>
        <Link
          to='/secretary/userEdit'
          className='secNavTile'
        >
          <FontAwesomeIcon icon="user-plus" className='faSecHome'/>
          <p className='secText'>Add User</p>
        </Link>
        <Link
          to='/secretary/fileUpload'
          className='secNavTile'
        >
          <FontAwesomeIcon icon="folder-plus" className='faSecHome marFix'/>
          <p className='secText'>Add Users Via CSV</p>
        </Link>
        <Link
          to='/secretary/events'
          className='secNavTile'
        >
          <FontAwesomeIcon icon="calendar-alt" className='faSecHome marFix'/>
          <p className='secText'>Events</p>
        </Link>
        <Link
          to='/secretary/eventEdit'
          className='secNavTile'
        >
          <FontAwesomeIcon icon="calendar-plus" className='faSecHome marFix'/>
          <p className='secText'>Add Event</p>
        </Link>
        <Link
          to='/secretary/userToEvent'
          className='secNavTile'
        >
          <FontAwesomeIcon icon="calendar-check" className='faSecHome marFix'/>
          <p className='secText'>Add Users to Event</p>
        </Link>
        <Link
          to='/secretary/eventFileUpload'
          className='secNavTile'
        >
          <FontAwesomeIcon icon="calendar-week" className='faSecHome marFix'/>
          <p className='secText'>Add Users to Event</p>
          <p className='secText'>Via CSV</p>
        </Link>
        <div className='secNavTile' onClick={this.handleUpdate}>
          <FontAwesomeIcon icon="sync" className='faSecHome marFix'/>
          <p className='secText center'>Update Standings</p>
        </div>
        <Link
          to='/secretary/statusAudit'
          className='secNavTile'
        >
          <FontAwesomeIcon icon="clipboard" className='faSecHome marFix'/>
          <p className='secText center'>Audit Statuses</p>
        </Link>
        <Link
          to='/secretary/statistics'
          className='secNavTile'
        >
          <FontAwesomeIcon icon="chart-bar" className='faSecHome marFix'/>
          <p className='secText center'>Gather Statistics</p>
        </Link>
      </div>
    );
  }
}
