import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App.js';

export class User extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
      ...props.match.params,
      FirstName: "",
      LastName: "",
      Email: "",
      DCE: "",
      Role: "M",
      Section: "---",
      ExtraSections: [],
      Status: "---",
      MGStanding: false,
      Exempt: false,
      ExemptDesc: "",
      GStanding: false,
      FGStanding: false,
      userEdit: false,
      delete: false,
      events: []
    }
    this.handleClick = this.handleClick.bind(this)
    this.confirm = this.confirm.bind(this)
  }

  componentDidMount() {
    if (this.state.selectedUser != null && this.state.selectedUser !== undefined) {
      var url =this.state.constants.hostname + '/api/secretary/user?editUser=' + this.state.selectedUser;
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then(data => {
        this.setState({FirstName: data[0].FirstName, LastName: data[0].LastName,
          Email: data[0].Email, DCE: data[0].DCE, Role: data[0].Role,
          Section: data[0].SECTION, Status: data[0].Status, MGStanding: data[0].MGStanding,
          Exempt: data[0].Exempt, ExemptDesc: data[0].ExemptDesc,
          GStanding: data[0].GStanding, FGStanding: data[0].FGStanding, Edit: true
        })
      })
      .catch(error => { console.error(error); })
      url = this.state.constants.hostname + '/api/secretary/userAttendance?userID=' + this.state.selectedUser;
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
      .then(res => {
          if (!res.ok) {
              return false;
          }
          return res.json();
      })
      .then(data => {
          if (!data) { return; }
          this.setState({ events : data });
      })
      .catch(error => { console.error(error); });
      url = this.state.constants.hostname + '/api/secretary/userSections?user=' + this.state.selectedUser;
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`}
      })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then(data => {
        if (!data) { return; }
        this.setState({ExtraSections: data});
      })
      .catch(error => { console.error(error); });
    }
  }

  handleClick() {
    this.setState({userEdit: true});
  }

  handleDownload() {
    let csvContent = "data:text/csv;charset=utf-8,"
      + this.state.events.map(e => `${e.Name},${e.DAY},${e.Type},${e.Semester}`).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    let now = new Date();
    link.setAttribute("download", `EventAttendance-${this.state.LastName}-${this.state.FirstName}-(${now.getMonth()+1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }

  confirm() {
    if (window.confirm("Delete this user?")) {
      var url = this.state.constants.hostname + '/api/secretary/removeUser?userInfo=' + this.state.DCE;
      fetch(url, {
        headers: {'Authorization':`${localStorage.getItem(USERID)}`},
        method: 'DELETE',
      })
      .then(res => {
        this.setState({delete: true})
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .catch(error => { console.error(error); });
    }
  }

  render() {
    return (
      <div>
        {this.state.userEdit?
          <Redirect to={`/secretary/userEdit/${this.state.DCE}`} />
          :
          null
        }
        {this.state.delete?
          <Redirect to='/secretary/users' />
          :
          null
        }
        <h3>User Profile for {this.state.LastName}, {this.state.FirstName}</h3>
        <div className='secBarNav'>
          <Link
            to='/secretary/users'
            className='secBarLink'
          >
            <p className='secBarText'>Back to Users</p>
          </Link>
          <FontAwesomeIcon
            icon="user-edit"
            className='faEdit'
            onClick={() => this.handleClick()}
          />
          <FontAwesomeIcon
            icon="file-download"
            className='faEdit'
            style={{marginLeft: "0px"}}
            onClick={() => this.handleDownload()}
          />
          <FontAwesomeIcon
            icon="trash-alt"
            className='faDelete'
            onClick={() => this.confirm()}
          />
        </div>
        <hr />
        <div className='userProfile'>
          <label className='secretaryLabel'>
            First Name: {this.state.FirstName}
          </label>
          <label className='secretaryLabel'>
            Last Name: {this.state.LastName}
          </label>
          <label className='secretaryLabel'>
            Email: {this.state.Email}
          </label>
          <label className='secretaryLabel'>
            DCE: {this.state.DCE}
          </label>
          <label className='secretaryLabel'>
            Role: {this.state.Role}
          </label>
          <label className='secretaryLabel'>
            Section: {this.state.Section}
          </label>
          <label className='secretaryLabel'>
            Other Sections: {this.state.ExtraSections.map(section => `${section.SECTION}, `)}
          </label>
          <label className='secretaryLabel'>
            Status: {this.state.Status}
          </label>
          <label className='secretaryLabel'>
            Manually Added Good Standing: {this.state.MGStanding===1?"true":"false"}
          </label>
          <label className='secretaryLabel'>
            Exempt: {this.state.Exempt===1?"true":"false"}
          </label>
          <label className='secretaryLabel'>
            Exemption Description: {this.state.ExemptDesc}
          </label>
          <label className='secretaryLabel'>
            Good Standing (Current Semester): {this.state.GStanding===1?"true":"false"}
          </label>
          <label className='secretaryLabel'>
            Good Standing (Future Semester): {this.state.FGStanding===1?"true":"false"}
          </label>
        </div>
        <h4>Individual User Attendance: ({this.state.events.length})</h4>
        <table className='userTable'>
          <thead>
            <tr className='userRow userHeaderRow'>
              <th className='userHeader'>Name</th>
              <th className='userHeader'>Date</th>
              <th className='userHeader'>Type</th>
              <th className='userHeader'>Semester</th>
            </tr>
          </thead>
          <tbody>
            {this.state.events.map(event =>
              <tr key={event.Eid} className='userRow' onClick={() => this.rowClick(event.Eid)}>
                <td className='userEntrySeparate'>{event.Name}</td>
                <td className='userEntrySeparate'>{event.DAY}</td>
                <td className='userEntrySeparate'>{event.Type}</td>
                <td className='userEntrySeparate'>{event.Semester}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(User)
