import React, { Component } from 'react';

export class NotFound extends Component {
    render() {
        return (
          <div id="notFound">
              <h2>404: Page not found</h2>
              <p>Please navigate to a different page to make sad RITchie feel better</p>
              <img className='' src='/media/sadtiger.jpg'
                alt="Sad tiger :("/>
          </div>
        );
    }
}
