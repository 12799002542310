import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { firebase } from "../index.js";
import { USERID } from "./App.js";

export class Logout extends Component {
    constructor(props) {
        super(props);
        firebase.auth().signOut();
        window.location.replace("/")
    }

    componentDidMount() {
      fetch(this.state.constants.hostname + '/api/logout')
      .then(res => {
          if (!res.ok) {
              return false;
          }
          return res.json();
      })
      .catch(error => { console.error(error); });
    }

    render() {
        localStorage.removeItem(USERID);
        localStorage.clear();
        return (
            <div id="logout">
                <Redirect to="/"/>
            </div>
        );
    }
}
