import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App.js';

export class Statistics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
      ...props.match.params,
      users: [],
      events: [],
      totalEventAttendance: 0,
      largestEvent: null,
      largestRehearsal: null,
      highestFallR: null,
      highestFallE: null,
      highestSpringR: null,
      highestSpringE: null,
    }
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/secretary/statistics';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then(data => {
        this.setState({users: data.users, events: data.events,
          totalEventAttendance: data.eventStats.totalEventAttendance.count,
          highestFallR: data.eventStats.highestFallR,
          highestFallE: data.eventStats.highestFallE,
          highestSpringR: data.eventStats.highestSpringR,
          highestSpringE: data.eventStats.highestSpringE,
        })
        if (data.eventStats.highestFallE !== null) {
          if (data.eventStats.highestSpringE === null) {
            this.setState({largestEvent: data.eventStats.highestFallE})
          }
          else if (data.eventStats.highestFallE.count > data.eventStats.highestSpringE.count) {
            this.setState({largestEvent: data.eventStats.highestFallE})
          } else {
            this.setState({largestEvent: data.eventStats.highestSpringE})
          }
        }
        if (data.eventStats.highestFallR !== null) {
          if (data.eventStats.highestSpringR === null) {
            this.setState({largestRehearsal: data.eventStats.highestFallR})
          }
          else if (data.eventStats.highestFallR.count > data.eventStats.highestSpringR.count) {
            this.setState({largestRehearsal: data.eventStats.highestFallR})
          } else {
            this.setState({largestRehearsal: data.eventStats.highestSpringR})
          }
        }
      })
      .catch(error => { console.error(error); })
  }

  handleDownload(data, filename) {
    let csvContent = "data:text/csv;charset=utf-8,"
      + data.map(e => `${e.SECTION},${e.LastName},${e.FirstName},${e.DCE}`).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    let now = new Date();
    link.setAttribute("download", `${filename}-(${now.getMonth()+1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }

  handleWomensAbleList() {
    var url = this.state.constants.hostname + '/api/secretary/wgamego';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
      .then(res => {
        if (!res.ok) {
          return false;
          }
          return res.json();
      })
      .then(data => {
          if (!data) { return; }
          if (data.length === 0) {
            alert("No users able to go to womens games at this time")
          } else {
            this.handleDownload(data, "W-Game-Eligible")
          }
      })
      .catch(error => { console.error(error); });
  }

  handleMensAbleList() {
    var url = this.state.constants.hostname + '/api/secretary/mgamego';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
      .then(res => {
        if (!res.ok) {
          return false;
          }
          return res.json();
      })
      .then(data => {
          if (!data) { return; }
          if (data.length === 0) {
            alert("No users able to go to mens games at this time")
          } else {
            this.handleDownload(data, "M-Game-Eligible")
          }
      })
      .catch(error => { console.error(error); });
  }

  handleGoodStanding() {
    var url = this.state.constants.hostname + '/api/secretary/goodStanding';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
      .then(res => {
        if (!res.ok) {
          return false;
          }
          return res.json();
      })
      .then(data => {
          if (!data) { return; }
          if (data.length === 0) {
            alert("No users with good standing at this time")
          } else {
            this.handleDownload(data, "Good-Standing")
          }
      })
      .catch(error => { console.error(error); });
  }

  render() {
    return (
      <div>
        <h3>Organization-wide Statistic Generators</h3>
        <div className='secBarNav'>
          <Link
            to='/secretary/home'
            className='secBarLink'
          >
            <p className='secBarText'>Back to Secretary Home</p>
          </Link>
        </div>
        <hr />
        <div>
          <div>
            <h3>General Statistics:</h3>
            <p>Users in System: {this.state.users.length}</p>
            <p>Events in System: {this.state.events.length}</p>
            <p>Total Attendance For All Events: {this.state.totalEventAttendance}</p>
            <p>
              Highest Attended Event For Both Semesters: {this.state.largestEvent===null || this.state.largestEvent.count===0?"No Events Have Taken Place":null}
            </p>
            {this.state.largestEvent===null || this.state.largestEvent.count===0?
              null
              :
              <div className='tableDiv'>
                <table className='userTable'>
                  <thead>
                    <tr className='userRow userHeaderRow'>
                      <th className='userHeader'>Name</th>
                      <th className='userHeader'>Type</th>
                      <th className='userHeader'>Date</th>
                      <th className='userHeader'>Number of Attendees</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr key={this.state.largestEvent.Name} className='userRow' style={{cursor: "auto"}}>
                    <td className='userEntrySeparate'>{this.state.largestEvent.Name}</td>
                    <td className='userEntrySeparate'>{this.state.largestEvent.Type}</td>
                    <td className='userEntrySeparate'>{this.state.largestEvent.DAY}</td>
                    <td className='userEntrySeparate'>{this.state.largestEvent.count}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            }
            <p>
              Highest Attended Rehearsal For Both Semesters: {this.state.largestRehearsal===null || this.state.largestRehearsal.count===0?"No Rehearsals Have Taken Place":null}
            </p>
            {this.state.largestRehearsal===null || this.state.largestRehearsal.count===0?
              null
              :
              <div className='tableDiv'>
                <table className='userTable'>
                  <thead>
                    <tr className='userRow userHeaderRow'>
                      <th className='userHeader'>Name</th>
                      <th className='userHeader'>Type</th>
                      <th className='userHeader'>Date</th>
                      <th className='userHeader'>Number of Attendees</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr key={this.state.largestRehearsal.Name} className='userRow' style={{cursor: "auto"}}>
                    <td className='userEntrySeparate'>{this.state.largestRehearsal.Name}</td>
                    <td className='userEntrySeparate'>{this.state.largestRehearsal.Type}</td>
                    <td className='userEntrySeparate'>{this.state.largestRehearsal.DAY}</td>
                    <td className='userEntrySeparate'>{this.state.largestRehearsal.count}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            }
            <p>
              Highest Attended Event For Fall Semester: {this.state.highestFallE===null || this.state.highestFallE.count===0?"No Events Have Taken Place":null}
            </p>
            {this.state.highestFallE===null || this.state.highestFallE.count===0?
              null
              :
              <div className='tableDiv'>
                <table className='userTable'>
                  <thead>
                    <tr className='userRow userHeaderRow'>
                      <th className='userHeader'>Name</th>
                      <th className='userHeader'>Type</th>
                      <th className='userHeader'>Date</th>
                      <th className='userHeader'>Number of Attendees</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr key={this.state.highestFallE.Name} className='userRow' style={{cursor: "auto"}}>
                    <td className='userEntrySeparate'>{this.state.highestFallE.Name}</td>
                    <td className='userEntrySeparate'>{this.state.highestFallE.Type}</td>
                    <td className='userEntrySeparate'>{this.state.highestFallE.DAY}</td>
                    <td className='userEntrySeparate'>{this.state.highestFallE.count}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            }
            <p>
              Highest Attended Event For Spring Semester: {this.state.highestSpringE===null || this.state.highestSpringE.count===0?"No Events Have Taken Place":null}
            </p>
            {this.state.highestSpringE===null || this.state.highestSpringE.count===0?
              null
              :
              <div className='tableDiv'>
                <table className='userTable'>
                  <thead>
                    <tr className='userRow userHeaderRow'>
                      <th className='userHeader'>Name</th>
                      <th className='userHeader'>Type</th>
                      <th className='userHeader'>Date</th>
                      <th className='userHeader'>Number of Attendees</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr key={this.state.highestSpringE.Name} className='userRow' style={{cursor: "auto"}}>
                    <td className='userEntrySeparate'>{this.state.highestSpringE.Name}</td>
                    <td className='userEntrySeparate'>{this.state.highestSpringE.Type}</td>
                    <td className='userEntrySeparate'>{this.state.highestSpringE.DAY}</td>
                    <td className='userEntrySeparate'>{this.state.highestSpringE.count}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            }
            <p>
              Highest Attended Rehearsal For Fall Semester: {this.state.highestFallR===null || this.state.highestFallR.count===0?"No Rehearsals Have Taken Place":null}
            </p>
            {this.state.highestFallR===null || this.state.highestFallR.count===0?
              null
              :
              <div className='tableDiv'>
                <table className='userTable'>
                  <thead>
                    <tr className='userRow userHeaderRow'>
                      <th className='userHeader'>Name</th>
                      <th className='userHeader'>Type</th>
                      <th className='userHeader'>Date</th>
                      <th className='userHeader'>Number of Attendees</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr key={this.state.highestFallR.Name} className='userRow' style={{cursor: "auto"}}>
                    <td className='userEntry'>{this.state.highestFallR.Name}</td>
                    <td className='userEntry'>{this.state.highestFallR.Type}</td>
                    <td className='userEntry'>{this.state.highestFallR.DAY}</td>
                    <td className='userEntry'>{this.state.highestFallR.count}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            }
            <p>
              Highest Attended Rehearsal For Spring Semester: {this.state.highestSpringR===null || this.state.highestSpringR.count===0?"No Rehearsals Have Taken Place":null}
            </p>
            {this.state.highestSpringR===null || this.state.highestSpringR.count===0?
              null
              :
              <div className='tableDiv'>
                <table className='userTable'>
                  <thead>
                    <tr className='userRow userHeaderRow'>
                      <th className='userHeader'>Name</th>
                      <th className='userHeader'>Type</th>
                      <th className='userHeader'>Date</th>
                      <th className='userHeader'>Number of Attendees</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr key={this.state.highestSpringR.Name} className='userRow' style={{cursor: "auto"}}>
                    <td className='userEntrySeparate'>{this.state.highestSpringR.Name}</td>
                    <td className='userEntrySeparate'>{this.state.highestSpringR.Type}</td>
                    <td className='userEntrySeparate'>{this.state.highestSpringR.DAY}</td>
                    <td className='userEntrySeparate'>{this.state.highestSpringR.count}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            }
          </div>
          <div>
            <h3>Download Statistics:</h3>
            <div
              onClick={() => this.handleWomensAbleList()}
              className="oneline csvDownload"
            >
              <FontAwesomeIcon icon="file-download" className="fileDownload"/>
              <p>Download All Users Able to Attend a Women's Hockey Game</p>
            </div>
            <div
              onClick={() => this.handleMensAbleList()}
              className="oneline csvDownload"
            >
              <FontAwesomeIcon icon="file-download" className="fileDownload"/>
              <p>Download All Users Able to Attend a Men's Hockey Game</p>
            </div>
            <div
              onClick={() => this.handleGoodStanding()}
              className="oneline csvDownload"
            >
              <FontAwesomeIcon icon="file-download" className="fileDownload"/>
              <p>Download All Users With Good Standing</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Statistics)
