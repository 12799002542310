import React, { Component } from 'react';

export class Memes extends Component {
  render() {
    return (
      <div>
        <img className='meme' src='/media/memes/1Teryn.jpeg' alt="Budget?"/>
        <img className='meme' src='/media/memes/alligators.jpg' alt="Dem Alligators"/>
        <img className='meme' src='/media/memes/ego.jpg' alt="What an ego"/>
        <img className='meme' src='/media/memes/egox2.jpg' alt="What an ego x2"/>
        <img className='meme' src='/media/memes/gina.jpg' alt="Gina"/>
        <img className='meme' src='/media/memes/ginacarb.jpg' alt="Gina with carbs"/>
        <img className='meme' src='/media/memes/IMG_4664.jpeg' alt="Llama"/>
        <img className='meme' src='/media/memes/intoxicated.jpg' alt="Definitely not"/>
        <img className='meme' src='/media/memes/noSection.jpeg' alt="What a winner"/>
        <img className='meme' src='/media/memes/pillow.jpg' alt="sleepy"/>
        <img className='meme' src='/media/memes/pinata.jpg' alt="big sad"/>
        <img className='meme' src='/media/memes/pizzawater.jpg' alt="pizza water. Obviously"/>
        <img className='meme' src='/media/memes/sense.jpg' alt="Makes sense"/>
        <img className='meme' src='/media/memes/shitlordbd.jpg' alt="poop lord"/>
      </div>
    );
  }
}
