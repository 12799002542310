import React, { Component } from 'react';

export class Mayonnaise extends Component {
  render() {
    return (
      <div>
        <iframe
          title='mayonnaise'
          width="1200"
          height="700"
          src="https://www.youtube.com/embed/71GWS3ccmvI?autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </div>
    );
  }
}
