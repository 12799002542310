import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { authRole } from '../index.js';
import { USERID } from './App'
import banner from '../static/banner.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../css/nav.css';

export class NavBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      dropdown: false,
      width: 0,
      role: this.props.state.constants.roles.nonMember
    };
    this.routeChange = this.routeChange.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    fetch(this.state.constants.hostname + '/api/checkAuth', {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then(data => {
        if (!data) { return; }
        this.setState({role: data.role});
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  routeChange() {
    this.props.history.push('/home');
  }

  render() {
    const roles = this.props.state.constants.roles;
    return (
      <div id="navBar" className='header'>
        <div className='bannerContainer'>
          <img
            src={banner}
            alt="Pepband Banner"
            className='banner'
            onClick={() => {
              if (authRole(this.props.state.userRole, roles.alumni)) {
                this.routeChange();
              }
            }}
          >
          </img>
        </div>
        <div className='dropdownMain'>
          <FontAwesomeIcon
            icon='align-justify'
            className='fa drop'
            onClick={() => {
              this.setState({ dropdown: !this.state.dropdown });
            }}
          />
          {this.state.width < 768 && !this.state.dropdown ?
            null
            :
            <div className='nav'>
              <ul className='navUI'>
                {authRole(this.props.state.userRole, roles.alumni) ?
                  (<Link
                    className='navLink'
                    to="/home"
                    onClick={() => {
                      this.setState({ dropdown: false });
                    }}
                  >
                    <li className='navElement'>
                      <FontAwesomeIcon icon="home" className='fa'/>
                      Home
                    </li>
                  </Link>) : (null)
                }
                {authRole(this.state.role, roles.president) ?
                  (<Link
                    className='navLink'
                    to="/president"
                    onClick={() => {
                      this.setState({ dropdown: false });
                    }}
                  >
                    <li className='navElement'>
                      <FontAwesomeIcon icon="crown" className='fa'/>
                      President
                    </li>
                  </Link>) : (null)
                }
                {authRole(this.state.role, roles.conductor) ?
                  (<Link
                    className='navLink'
                    to="/conductor"
                    onClick={() => {
                      this.setState({ dropdown: false });
                    }}
                  >
                    <li className='navElement'>
                      <FontAwesomeIcon icon="music" className='fa'/>
                      Conductor
                    </li>
                  </Link>) : (null)
                }
                {authRole(this.state.role, roles.secretary) ?
                  (<Link
                    className='navLink'
                    to="/secretary"
                    onClick={() => {
                      this.setState({ dropdown: false });
                    }}
                  >
                    <li className='navElement'>
                      <FontAwesomeIcon icon="user-edit" className='fa'/>
                      Secretary
                    </li>
                  </Link>) : (null)
                }
                {authRole(this.props.state.userRole, roles.alumni) ?
                  (<Link
                    className='navLink'
                    to="/eboard"
                    onClick={() => {
                      this.setState({ dropdown: false });
                    }}
                  >
                    <li className='navElement'>
                      <FontAwesomeIcon icon="graduation-cap" className='fa'/>
                      EBoard
                    </li>
                  </Link>) : (null)
                }
                {authRole(this.props.state.userRole, roles.alumni) ?
                  (<Link
                    className='navLink'
                    to='/vote'
                    onClick={() => {
                      this.setState({ dropdown: false });
                    }}
                  >
                    <li className='navElement'>
                    <FontAwesomeIcon icon="check-square" className='fa'/>
                      Voting
                    </li>
                  </Link>) : (null)
                }
                {authRole(this.props.state.userRole, roles.alumni) ?
                  (<Link
                    className='navLink'
                    to="/documents"
                    onClick={() => {
                      this.setState({ dropdown: false });
                    }}
                  >
                    <li className='navElement'>
                      <FontAwesomeIcon icon="folder-open" className='fa'/>
                      Documents
                    </li>
                  </Link>) : (null)
                }
                {authRole(this.state.role, roles.member) ?
                  (<Link
                    className='navLink'
                    to="/attendance"
                    onClick={() => {
                      this.setState({ dropdown: false });
                    }}
                  >
                    <li className='navElement'>
                      <FontAwesomeIcon icon="address-card" className='fa'/>
                      My Attendance
                    </li>
                  </Link>) : (null)
                }
                <Link
                  className='navLink'
                  to="/chants"
                  onClick={() => {
                    this.setState({ dropdown: false });
                  }}
                >
                  <li className='navElement'>
                    <FontAwesomeIcon icon="file-alt" className='fa'/>
                    Chants
                  </li>
                </Link>
                {authRole(this.props.state.userRole, roles.alumni) ?
                  (<Link
                    className='navLink'
                    to="/logout"
                    onClick={() => {
                      this.setState({ dropdown: false });
                    }}
                  >
                    <li className='navElement'>
                      <FontAwesomeIcon icon='sign-out-alt' className='fa'/>
                      Logout
                    </li>
                  </Link>) : (null)
                }
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(NavBar);
