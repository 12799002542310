import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import '../../css/secretary.css'

import { Home } from './SecretaryPages/Home'
import { Users } from './SecretaryPages/Users';
import { Events } from './SecretaryPages/Events';
import Event from './SecretaryPages/Event';
import EventEdit from './SecretaryPages/EventEdit';
import UserEdit from './SecretaryPages/UserEdit';
import CSV from './SecretaryPages/csv';
import CSVEvent from './SecretaryPages/csvEvent';
import UserToEvent from './SecretaryPages/userToEvent';
import User from './SecretaryPages/User';
import StatusCheck from './SecretaryPages/StatusCheck';
import Statistics from './SecretaryPages/Statistics';

export class Secretary extends Component {

  constructor(props) {
    super(props)
    this.state=this.props.state;
  }

  render() {
    return (
      <div id="secretary">
        <h2>Secretary</h2>
        <Switch>
          <Route path='/secretary/home' component={() => <Home state={this.state} />}/>
          <Redirect exact from='/secretary' to='/secretary/home'/>
          <Route path='/secretary/users' component={() => <Users state={this.state} />}/>
          <Route path='/secretary/user/:selectedUser' component={() => <User state={this.state} />}/>
          <Route path='/secretary/userEdit/:selectedUser' component={() => <UserEdit state={this.state} />}/>
          <Route path='/secretary/userEdit' component={() => <UserEdit state={this.state} />}/>
          <Route path='/secretary/fileUpload' component={() => <CSV state={this.state} />}/>
          <Route path='/secretary/events' component={() => <Events state={this.state} />}/>
          <Route path='/secretary/event/:eventID' component={() => <Event state={this.state} />}/>
          <Route path='/secretary/eventEdit/:eventID' component={() => <EventEdit state={this.state} />}/>
          <Route path='/secretary/eventEdit' component={() => <EventEdit state={this.state} />}/>
          <Route path='/secretary/eventFileUpload' component={() => <CSVEvent state={this.state} />} />
          <Route path='/secretary/userToEvent' component={() => <UserToEvent state={this.state} />} />
          <Route path='/secretary/statusAudit' component={() => <StatusCheck state={this.state} />} />
          <Route path='/secretary/statistics' component={() => <Statistics state={this.state} />} />
          <Redirect to='/404' />
        </Switch>
      </div>
    );
  }
}
