import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import "../../../css/qdb.css";
import { USERID } from '../../App.js';

export class ApproveQuote extends Component {
  constructor(props) {
    super(props)
    this.state={...this.props.state, quotes:[]};
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/qdb/private';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then(data => {
      if (!data) { return; }
      this.setState({ quotes : data });
    })
    .catch(error => { console.error("error"); });
  }

  confirmApprove(qid) {
    if (window.confirm("Approve this quote?")) {
      var url = this.state.constants.hostname + '/api/qdb/approveQuote';
      fetch(url, {
        body: JSON.stringify({qid: qid}),
        headers: {
          'Authorization':`${localStorage.getItem(USERID)}`,
          'Content-Type':'application/json'
        },
        method: 'PATCH',
      })
      .then(res => {
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .then(completed => {
        let tempQuotes = [];
        this.state.quotes.forEach(quote => {
          if (quote.QID !== qid) {
            tempQuotes.push(quote);
          }
        });
        this.setState({quotes: tempQuotes})
        window.alert("Successful Approval");
      })
      .catch(error => { console.error(error); });
    }
  }

  confirmDel(qid) {
    if (window.confirm("Delete this quote?")) {
      var url = this.state.constants.hostname + '/api/qdb/removeQuote';
      fetch(url, {
        body: JSON.stringify({QID: qid}),
        headers: {
          'Authorization':`${localStorage.getItem(USERID)}`,
          'Content-Type':'application/json',
        },
        method: 'DELETE',
      })
      .then(res => {
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .then(completed => {
        let tempQuotes = [];
        this.state.quotes.forEach(quote => {
          if (quote.QID !== qid) {
            tempQuotes.push(quote);
          }
        });
        this.setState({quotes: tempQuotes})
        window.alert("Successful deletion");
      })
      .catch(error => { console.error(error); });
    }
  }

  render() {
    return (
      <div id="Qdb">
        <h2>QDB</h2>
        <div className='oneline'>
          <Link
            to='/qdb/addQuote'
            className='secBarLink'
            state={this.state}
          >
            <div className='oneline'>
              <FontAwesomeIcon icon="plus-circle" className='faAdd'/>
              <p className='secBarText'>
                Add Quote
              </p>
            </div>
          </Link>
          <Link
            to='/qdb/home'
            className='secBarLink'
            state={this.state}
          >
            <div className='oneline'>
              <FontAwesomeIcon icon="home" className='faAdd'/>
              <p className='secBarText'>
                Quotes Home
              </p>
            </div>
          </Link>
        </div>
        <table className="QDBApproval">
          <tbody>
            <tr>
              <th className="QDBApproval"/>
              <th className="QDBApproval">Title</th>
              <th className="QDBApproval">Date</th>
              <th className="QDBApproval">Person</th>
              <th className="QDBApproval">Quote</th>
              <th className="QDBApproval"/>
            </tr>
            {this.state.quotes.map(quote =>
              <tr key={quote.QID}>
              <td className="QDBApproval">
                <FontAwesomeIcon
                  icon="check-square"
                  className='faApprove'
                  onClick={() => this.confirmApprove(quote.QID)}
                />
              </td>
                <td className="QDBApproval">{quote.Title}</td>
                <td className="QDBApproval">{quote.Date}</td>
                <td className="QDBApproval">{quote.Person}</td>
                <td className="QDBApproval multiline">
                  <ReactMarkdown source={quote.Quote} />
                </td>
                <td className="QDBApproval">
                <FontAwesomeIcon
                  icon="trash-alt"
                  className='faDelete'
                  onClick={() => this.confirmDel(quote.QID)}
                />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
