import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { USERID } from './App.js'
import { authRole } from "../index.js";

import { Home } from './routes/Home.js';
import { Admin } from './routes/Admin.js';
import { President } from './routes/President.js';
import { Conductor } from './routes/Conductor.js';
import { Secretary } from './routes/Secretary.js';
import { EBoard } from './routes/EBoard.js';
import { Documents } from './routes/Documents.js';
import { Attendance } from './routes/Attendance.js';
import { Chants } from './routes/Chants.js';
import { History } from "./routes/History.js";
import { Mayonnaise } from './routes/Mayonnaise.js';
import { Ready } from './routes/Ready.js';
import { Memes } from './routes/Memes.js'
import { MusicList } from './routes/MusicList.js';
import Music from './routes/Music.js';
import Loading from './Loading.js';
import { Qdb } from './routes/Qdb.js'
import { AntiCss } from './routes/AntiCss.js';
import { Vote } from './routes/Vote.js'
import { Rickroll } from './routes/Rickroll.js';

import { NotFound } from './routes/NotFound.js';

export class Routes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      role: this.props.state.constants.roles.nonMember,
      load: false,
    }
  }

  componentDidMount() {
    fetch(this.state.constants.hostname + '/api/checkAuth', {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then(data => {
        if (!data) { return; }
        this.setState({role: data.role, load: true});
    })
    .catch(error => { console.error(error); });
  }

  render() {
    const roles = this.state.constants.roles;
    if (!this.state.load && (window.location.pathname !== "/chants")) {
      return (
        <Loading />
      );
    }
    return (
      <Switch>
        <Route exact path='/home' component={() => <Home state={this.state}/>}/>
        <Redirect exact from="/" to="/home"/>
        <Route exact path="/admin" component={() =>
          authRole(this.state.role, roles.admin) ? (<Admin state={this.state}/>) : (<Redirect to="/404"/> )}/>
        <Route path='/president' component={() =>
          authRole(this.state.role, roles.president) ? (<President state={this.state}/>) : (<Redirect to="/404"/> )}/>
        <Route path='/conductor' component={() =>
          authRole(this.state.role, roles.conductor) ? (<Conductor state={this.state}/>) : (<Redirect to="/404"/> )}/>
        <Route path='/secretary' component={() =>
          authRole(this.state.role, roles.secretary) ? (<Secretary state={this.state}/>) : (<Redirect to="/404"/> )}/>
        <Route path='/eboard' component={() => <EBoard state={this.state}/>}/>
        <Route path='/vote' component={() => <Vote state={this.state}/>}/>
        <Route path='/documents' component={Documents}/>
        <Route path='/attendance' component={() => <Attendance state={this.state}/>}/>
        <Route path='/chants' component={Chants}/>
        <Route path='/history' component={History}/>
        <Route path='/qdb' component={() => <Qdb state={this.state}/>}/>
        <Route exact sensitive path='/music' component={() => <MusicList state={this.state}/>}/>
        <Route exact path='/music/:song' component={() => <Music state={this.state}/>}/>
        <Route sensitive exact path='/Music' component={Rickroll}/>
        <Route path='/mayonnaise' component={Mayonnaise}/>
	<Route path='/anticsschurch' component={() => <AntiCss state={this.state}/>}/>
        <Route exact path='/ready' component={() => <Ready routes={{routes:1}}/>}/>
        <Route exact path='/ready/hear' component={() => <Ready routes={{routes:2}}/>}/>
        <Route exact path='/ready/hear/opener' component={() => <Ready routes={{routes:3}}/>}/>
        <Route exact path='/404' component={NotFound}/>
        <Route path='/memes' component={Memes}/>
        <Redirect to='/404'/>
      </Switch>
    );
  }
}
