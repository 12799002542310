import React, { Component } from "react";
import { USERID } from '../../App.js';
import { Link, Redirect, withRouter } from "react-router-dom";
import { blankCheck, eventCheck } from "../../Errors.js";

export class CreateElection extends Component{
    constructor(props) {
        super(props)
        this.state = {
            ...this.props.state,
            title: "",
            options: [""],
            endDate: "",
            abstainable: "Y"
        }
    }

    handleTitleChange = (event) => {
        this.setState({title: event.target.value})
    }

    handleAddOption = (event) => {
        this.setState({options: this.state.options.concat([""])})
    }

    handleRemoveOption = (event) => {
        if(this.state.options.length === 1) {
            window.alert("An Election Must have at Least 1 candidate")
        }
        else {
            let newOptions = this.state.options
            newOptions.splice(event.target.id, 1)
            this.setState({options: newOptions})
        }
    }

    handleOptionChange = (event) => {
        let newOptions = this.state.options
        newOptions[event.target.id] = event.target.value 
        this.setState({options: newOptions})
    }

    handleDateChange = (event) => {
        this.setState({endDate: event.target.value})
    }

    handleAbstainChange = (event) => {
        this.setState({abstainable: event.target.value})
    }

    errorCheck = () => {
        if (blankCheck(this.state.title)) {
            console.error("Blank field: Name")
            alert("Blank field: First Name")
            return false;
        }
        if(eventCheck(this.state.title)) {
            console.error("Invalid Name: " + this.state.title)
            alert("Invalid Name: " + this.state.Name + "\nPlease fix input");
            return false;
        }
        if (blankCheck(this.state.endDate)) {
            console.error("Blank field: End Date")
            alert("Blank field: Date")
            return false;
        }
        return true;
      }

    handleSubmit = (event) => {
        if(this.errorCheck()) {
            this.setState({Error: false})
            const election = {
                title: this.state.title,
                endDate: this.state.endDate,
                candidates: this.state.options,
                abstainable: this.state.abstainable
            }
            var url = this.state.constants.hostname + '/api/voting/createElection';
            fetch(url, {
                body: JSON.stringify({ election: election }),
                headers: {
                'Authorization':`${localStorage.getItem(USERID)}`,
                'Content-Type': 'application/json',
                },
                method: 'POST',
            })
            .then(res => {
                if (!res.ok) {
                    this.setState({Error: true})
                    return false;
                }
                else {
                    this.setState({Submit: true})
                }
            })
            .catch(error => { console.error(error); })
        }
    }

    render() {
        return (
            <div>
                {this.state.Submit?
                    <Redirect to={'/vote'} />
                    :
                    null
                }
                <Link
                    to='/vote'
                    className='secBarLink'
                >
                    <p className='secBarText'>Back to "Election Home"</p>
                </Link>
                <h2>Create Election</h2>
                <label for="title">Election Title (NO SPACES -'s are fine'):</label>
                <input id="title" type="text" onChange={this.handleTitleChange}></input>
                <br/>
                <label className='secretaryLabel'>
                    Can Users Abstain?
                    <select
                    value={this.state.abstainable}
                    onChange={this.handleAbstainChange}
                    className="secretaryInput"
                    >
                    <option default value="Y">Yes</option>
                    <option value="N">No</option>
                    </select>
                </label>
                <br/>
                <button onClick={this.handleAddOption}>Add Candidate</button>
                {this.state.options.map(option =>
                    <div>
                        <input type="text" default={option} id={this.state.options.indexOf(option)} onChange={this.handleOptionChange}></input>
                        <button id={this.state.options.indexOf(option)} onClick={this.handleRemoveOption}>Remove</button>
                        <br/>
                    </div>
                )}
                <br/>

                <label for="date">End Date: </label>
                <input id="date" type="datetime-local" onChange={this.handleDateChange}></input>
                <br/>

                <button onClick={this.handleSubmit}>Create</button>
                
            </div>
        )
    }
}

export default withRouter(CreateElection)